import { memo, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import * as queryString from "query-string";
import styled from "styled-components";

import * as TYPES from "../constants/actionTypes";
import { periodicUpdateCycle } from "../constants/options";

import { Map } from "./Map";
import { Navigation } from "../components/Navigation";
import { DeviceList } from "../components/DeviceList";
import { Page } from "./Page";

import { SpinnerSmall } from "../components/Spinner";
import {
  DeviceType,
  MachineType,
  SelectableColumns,
} from "../interfaces/types";
import { Header } from "../components/Header";
import { DevicesPage, DeviceMapSplit } from "../components/Common";
import { isTokenExpired } from "../utils/utils";
import { Redirect } from "react-router";
import { DeviceModal, TabType } from "../components/DeviceModal/DeviceModal";
import { visibleDevicesSelector } from "../selectors/devices";

const Loading = styled.div`
  height: 100%;
  background: rgba(0, 0, 0, 0.15);

  > div {
    text-align: center;
    top: 50%;
    position: relative;
    transform: translateY(-50%);
  }
`;

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchDevices: (token: string, initialFetch: boolean) => {
      dispatch({
        type: TYPES.FETCH_DEVICES,
        payload: {
          token: token,
          initialFetch: initialFetch,
        },
      });
    },
  };
};

const mapStateToProps = (state: any) => {
  return {
    token: state.token,
    isFetching: state.devices.isFetching,
    devices: visibleDevicesSelector(state),
    loadedDevices: state.devices.loadedDevices,
    users: state.users,
    deviceColumnsSelected: state.users.deviceColumnsSelected,
  };
};

interface DevicesProps {
  fetchDevices(token: string, initialFetch: boolean): void;
  token: any;
  isFetching: boolean;
  devices: Array<DeviceType>;
  loadedDevices: number;
  users: any;
  statusUpdate: boolean;
  deviceColumnsSelected: SelectableColumns[];
  match: any;
  history: any;
  location: any;
}

// Memoize the header so it doesn't re-render everytime the page state updates.
// In this case, prevents the feedback form from reloading every time the
// refetchInterval fires.
const DevicesHeader = memo(Header);

const DevicesConnected = (props: DevicesProps) => {
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [activeDevice, setActiveDevice] = useState<DeviceType | undefined>(
    undefined
  );
  const [showAnalytics, setShowAnalytics] = useState<boolean>(false);
  const [activeModalTab, setActiveModalTab] = useState<number>(
    TabType.DEVICE_DETAILS
  );

  const refetchIntervalRef = useRef<NodeJS.Timeout | undefined>();

  const {
    fetchDevices,
    token,
    isFetching,
    devices,
    loadedDevices,
    users,
    deviceColumnsSelected,
    history,
    location,
  } = props;

  useEffect(() => {
    if (loadedDevices === 0) {
      getDevices(true);
    }
    setRefetchInterval();
    window.addEventListener("visibilitychange", handleVisibilityChange, true);
    return () => clearInterval(refetchIntervalRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFetching) {
      // Handle opening device modal if there's deviceId in url
      if (0 < location.search.length) {
        const { serialNumber } = queryString.parse(location.search.slice(1));
        setOpenPopup(serialNumber !== undefined);

        setActiveDevice(
          devices.find(
            (device: DeviceType) => device.serialNumber === serialNumber
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const getDevices = (initialFetch: boolean = false) => {
    if (token.key !== "" && !isFetching && !isTokenExpired(token)) {
      fetchDevices(token.key, initialFetch);
    }
  };

  const setRefetchInterval = () => {
    if (refetchIntervalRef.current) {
      // Reset the interval if it's already running
      clearInterval(refetchIntervalRef.current);
    }
    refetchIntervalRef.current = setInterval(() => {
      getDevices();
    }, periodicUpdateCycle.defaultUpdateCycle);
  };

  const handleVisibilityChange = (): void => {
    if (document.visibilityState === "visible") {
      setRefetchInterval();
    } else {
      clearInterval(refetchIntervalRef.current);
    }
  };

  const handleEditDevice = (device: DeviceType): void => {
    setOpenPopup(true);
    setActiveDevice(device);
    setActiveModalTab(TabType.DEVICE_DETAILS);

    history.replace({
      pathname: `/devices`,
      search: queryString.stringify({
        serialNumber: device.serialNumber,
      }),
    });
  };
  const handleEditDeviceClose = (): void => {
    setOpenPopup(false);
    setActiveDevice(undefined);

    history.replace({
      pathname: `/devices`,
      search: "",
    });
  };

  const handleShowServiceRequests = (device: DeviceType): void => {
    setOpenPopup(true);
    setActiveDevice(device);
    setActiveModalTab(TabType.SERVICE_REQUESTS);

    history.replace({
      pathname: `/devices`,
      search: queryString.stringify({
        serialNumber: device.serialNumber,
      }),
    });
  };

  const handleShowAnalytics = (device: DeviceType): void => {
    setOpenPopup(true);
    setShowAnalytics(true);
    setActiveDevice(device);
    setActiveModalTab(TabType.DATA_ANALYTICS);

    history.push({
      pathname: `/devices`,
      search: queryString.stringify({
        serialNumber: device.serialNumber,
      }),
    });
  };

  if (showAnalytics) {
    return (
      <Redirect
        to={{
          pathname: `/analytics/${activeDevice?.guid || ""}`,
        }}
      />
    );
  }

  return (
    <Page>
      <DevicesPage>
        <DevicesHeader />
        {isFetching ? (
          <Loading>
            <div>
              <SpinnerSmall />
            </div>
          </Loading>
        ) : (
          <>
            <Navigation
              searchActive={true}
              visibleDevicesCount={devices.length}
            />
            <DeviceMapSplit
              renderDeviceTable={(contentRef) => (
                <>
                  <DeviceModal
                    deviceOpen={openPopup}
                    device={activeDevice}
                    activeTab={activeModalTab}
                    handleCloseDevice={handleEditDeviceClose}
                    user={users}
                  />
                  <DeviceList
                    devices={devices}
                    contentRef={contentRef}
                    columnsSelected={deviceColumnsSelected}
                    contentType={MachineType.Compactor}
                    onEditDevice={handleEditDevice}
                    onShowServiceRequests={handleShowServiceRequests}
                    onShowAnalytics={handleShowAnalytics}
                  />
                </>
              )}
              mapChildren={
                <Map
                  deviceTypesOnMap={MachineType.Compactor}
                  onEditDevice={handleEditDevice}
                  onShowAnalytics={handleShowAnalytics}
                />
              }
            />
          </>
        )}
      </DevicesPage>
    </Page>
  );
};

export const Devices = connect(
  mapStateToProps,
  mapDispatchToProps
)(DevicesConnected);
