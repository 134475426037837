import { useDispatch, useSelector } from "react-redux";
import { DeviceType } from "../../interfaces/types";
import * as deviceApi from "../../api/devices";
import * as TYPES from "../../constants/actionTypes";
import { Notify } from "../Common/Notify";
import { Field, FieldArray, Form, Formik } from "formik";
import { DeviceDetailsSchema } from "./DeviceDetailsSchema";
import { FormInput, FormLabel, FormToggle } from "../Common/FormInput";
import { FlexBoxWrapper } from "../Common";
import { FormSubmitButton } from "../Common/FormSubmitButton";
import { isEmpty } from "lodash";
import { Spinner } from "../Spinner";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getNotificationByAction } from "../../utils/utils";

interface ScaleDetailsProps {
  device: DeviceType;
  admin: boolean;
}

const SectionWrapper = styled.div`
  margin: 1rem 0;
`;

const SettingsRow = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const RecipientsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 100px;
`;

const Recipient = styled.div`
  display: flex;
  margin-top: 5px;

  input {
    height: 22px;
    line-height: 22px;
    display: inline-flex;
    flex-grow: 1;
    border: solid 1px var(--light-blue-grey);
    border-radius: 4px;
    padding-left: 5px;
  }

  button {
    margin-left: 10px;
    height: 23px;
    width: 23px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: condensed;
    font-style: normal;
    line-height: 23px;
    letter-spacing: 0.25px;
    border-radius: 4px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    color: #0d467d;
    background-color: #ffffff;
    cursor: pointer;
    border: solid 1px rgba(0, 0, 0, 0.1);
  }
`;

const AddRecipientButton = styled.button`
  max-width: 100px;
  margin-top: 5px;
  height: 23px;
  line-height: 23px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 10px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: 0.25px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #0d467d;
  color: #ffffff;
  cursor: pointer;
  border: solid 1px rgba(0, 0, 0, 0.1);
`;

export function ScaleDetails(props: ScaleDetailsProps) {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.token.key);
  const { t } = useTranslation();

  useEffect(() => {
    if (props.device) {
      dispatch({
        type: TYPES.FETCH_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS,
        payload: {
          deviceSerialNumber: props.device.serialNumber,
          token,
        },
      });
    }
  }, [props.device, dispatch, token]);

  const notificationSettings = useSelector(
    (state: any) => state.scale.notificationSettings || []
  );

  const initialValues = {
    scaleTotalWeightEmptyingLimit:
      props.device?.scaleTotalWeightEmptyingLimit || 1,
    scaleTotalWeightAfterLastEmptied:
      props.device?.scaleTotalWeightAfterLastEmptied || 0,
  };

  const [scaleDetailsOn, setScaleDetailsOn] = useState(
    (props.device?.scaleTotalWeightEmptyingLimit || 0) > 0
  );

  const changeScaleDetailsToggle = async () => {
    setScaleDetailsOn(!scaleDetailsOn);
    if (scaleDetailsOn) {
      try {
        const serialNumber = props.device?.serialNumber;
        const result = await deviceApi.patchDeviceEmptyingLimit({
          payload: {
            token,
            serialNumber,
            scaleTotalWeightEmptyingLimit: 0,
          },
        });
        dispatch({
          type: TYPES.PATCH_DEVICE_SCALE_EMPTYING_LIMIT_SUCCESS,
          payload: { ...result.data, serialNumber },
        });
        Notify.success(
          getNotificationByAction(
            TYPES.PATCH_DEVICE_SCALE_EMPTYING_LIMIT_SUCCESS
          )
        );
      } catch (e) {
        Notify.error(getNotificationByAction(TYPES.DEFAULT_ERROR));
      }
    } else {
      try {
        const serialNumber = props.device?.serialNumber;
        const result = await deviceApi.patchDeviceEmptyingLimit({
          payload: {
            token,
            serialNumber,
            scaleTotalWeightEmptyingLimit: 1,
          },
        });
        dispatch({
          type: TYPES.PATCH_DEVICE_SCALE_EMPTYING_LIMIT_SUCCESS,
          payload: { ...result.data, serialNumber },
        });
        Notify.success(
          getNotificationByAction(
            TYPES.PATCH_DEVICE_SCALE_EMPTYING_LIMIT_SUCCESS
          )
        );
      } catch (e) {
        Notify.error(getNotificationByAction(TYPES.DEFAULT_ERROR));
      }
    }
  };

  const onEmptyingLimitSubmit = async (values: typeof initialValues) => {
    const serialNumber = props.device?.serialNumber;
    const scaleTotalWeightEmptyingLimit = values.scaleTotalWeightEmptyingLimit;

    try {
      const result = await deviceApi.patchDeviceEmptyingLimit({
        payload: {
          token,
          serialNumber,
          scaleTotalWeightEmptyingLimit,
        },
      });
      dispatch({
        type: TYPES.PATCH_DEVICE_SCALE_EMPTYING_LIMIT_SUCCESS,
        payload: { ...result.data, serialNumber },
      });
      Notify.success(
        getNotificationByAction(TYPES.PATCH_DEVICE_SCALE_EMPTYING_LIMIT_SUCCESS)
      );
    } catch (e) {
      Notify.error(getNotificationByAction(TYPES.DEFAULT_ERROR));
    }
  };

  const onScaleWeightSubmit = async (values: typeof initialValues) => {
    const serialNumber = props.device?.serialNumber;
    const scaleTotalWeightAfterLastEmptied =
      values.scaleTotalWeightAfterLastEmptied;

    try {
      const result = await deviceApi.patchDeviceTotalWeight({
        payload: {
          token,
          serialNumber,
          scaleTotalWeightAfterLastEmptied,
        },
      });
      dispatch({
        type: TYPES.PATCH_DEVICE_SCALE_TOTAL_WEIGHT_SUCCESS,
        payload: { ...result.data, serialNumber },
      });
      Notify.success(
        getNotificationByAction(TYPES.PATCH_DEVICE_SCALE_TOTAL_WEIGHT_SUCCESS)
      );
    } catch (e) {
      Notify.error(getNotificationByAction(TYPES.DEFAULT_ERROR));
    }
  };

  function handleSettingsSubmit(recipients: string[]) {
    dispatch({
      type: TYPES.UPDATE_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS,
      payload: {
        recipients: recipients,
        deviceSerialNumber: props.device.serialNumber,
        token,
      },
    });
  }

  return (
    <>
      {props.admin && (
        <SectionWrapper>
          <FormToggle
            id="scaleDetailsToggle"
            label={"Track fill level based on scale results"}
            labelColor={"#000"}
            labelInfoTooltip={`Turn on/off fill level tracking based on scale results.

If enabled, fill level will update based on scale results and user set weight limit.
When total weight exceeds weight limit, emptying request will be sent to email recipients and total weight will reset.

This switch is only visible for admin users`}
            onChange={changeScaleDetailsToggle}
            selected={scaleDetailsOn}
          />
        </SectionWrapper>
      )}
      {scaleDetailsOn && (
        <SectionWrapper>
          <Formik
            initialValues={initialValues}
            onSubmit={onScaleWeightSubmit}
            validationSchema={DeviceDetailsSchema}
          >
            {({
              errors,
              values,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
            }) => (
              <Form>
                <FormInput
                  id="scaleTotalWeightAfterLastEmptied"
                  type="number"
                  label={"Total weight (kilograms)"}
                  labelInfoTooltip={`Total weight of all the scale results in kilograms.

Total weight will be reset when it exceeds the weight limit. This value can be manually changed if needed.`}
                  error={
                    touched.scaleTotalWeightAfterLastEmptied
                      ? errors.scaleTotalWeightAfterLastEmptied
                      : undefined
                  }
                  value={values.scaleTotalWeightAfterLastEmptied}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelColor={"#000"}
                />
                <FlexBoxWrapper marginTop={5}>
                  <FormSubmitButton
                    type="submit"
                    disabled={!isEmpty(errors) || isSubmitting}
                  >
                    {isSubmitting ? <Spinner height="100%" /> : "Save"}
                  </FormSubmitButton>
                </FlexBoxWrapper>
              </Form>
            )}
          </Formik>
          <Formik
            initialValues={initialValues}
            onSubmit={onEmptyingLimitSubmit}
            validationSchema={DeviceDetailsSchema}
          >
            {({
              errors,
              values,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
            }) => (
              <Form>
                <FormInput
                  id="scaleTotalWeightEmptyingLimit"
                  type="number"
                  label={"Weight limit (kilograms)"}
                  labelInfoTooltip={`Weight limit in kilograms.

When weight limit is reached, emptying request will be sent by email to recipients and total weight will reset.

Default value is 1kg, and it should be changed.`}
                  error={
                    touched.scaleTotalWeightEmptyingLimit
                      ? errors.scaleTotalWeightEmptyingLimit
                      : undefined
                  }
                  value={values.scaleTotalWeightEmptyingLimit}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelColor={"#000"}
                />
                <FlexBoxWrapper marginTop={5}>
                  <FormSubmitButton
                    type="submit"
                    disabled={!isEmpty(errors) || isSubmitting}
                  >
                    {isSubmitting ? <Spinner height="100%" /> : "Save"}
                  </FormSubmitButton>
                </FlexBoxWrapper>
              </Form>
            )}
          </Formik>
          {notificationSettings && (
            <Formik
              enableReinitialize={true}
              initialValues={{
                recipients: notificationSettings.recipients,
              }}
              onSubmit={(values) => {
                handleSettingsSubmit(values.recipients);
              }}
              render={({ values }) => (
                <Form>
                  <FieldArray
                    name="recipients"
                    render={(arrayHelpers) => (
                      <div>
                        <SettingsRow>
                          <FormLabel color="black">
                            {t("scale.reports.recipients", "Recipients")}:
                          </FormLabel>
                          <RecipientsWrapper>
                            {values.recipients &&
                            values.recipients.length > 0 ? (
                              values.recipients.map(
                                (_recipient: any, index: number) => (
                                  <Recipient key={index}>
                                    <Field name={`recipients.${index}`} />
                                    <button
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      -
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        arrayHelpers.insert(index + 1, "")
                                      }
                                    >
                                      +
                                    </button>
                                  </Recipient>
                                )
                              )
                            ) : (
                              <AddRecipientButton
                                type="button"
                                onClick={() => arrayHelpers.push("")}
                              >
                                {t(
                                  "scale.reports.addRecipient",
                                  "Add recipient"
                                )}
                              </AddRecipientButton>
                            )}
                          </RecipientsWrapper>
                        </SettingsRow>
                        <FlexBoxWrapper marginTop={5}>
                          <FormSubmitButton type="submit">
                            {t("scale.reports.save", "Save")}
                          </FormSubmitButton>
                        </FlexBoxWrapper>
                      </div>
                    )}
                  />
                </Form>
              )}
            />
          )}
        </SectionWrapper>
      )}
    </>
  );
}
