import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { connect, useDispatch, useSelector } from "react-redux";
import * as TYPES from "../../constants/actionTypes";
import { ScalePage } from "../Scale/ScalePage";
import { Table, TableSettings } from "../../components/Common/Table";
import { formatISO9075, parseISO } from "date-fns";
import { SortingRule } from "react-table";
import { getParentChain } from "../../utils/group";
import { groupHierarchiesSelector } from "../../selectors/groups";
import { DeviceType } from "../../interfaces/types";

const Heading = styled.div`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--col-1-c-2020);
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
`;

const TableContainer = styled.div`
  > * {
    margin: 1rem;
  }
`;

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateDashboardWorkLog: (
      token: string,
      deviceId: string,
      issueType: string,
      checked: boolean
    ) => {
      dispatch({
        type: TYPES.POST_DASHBOARD_WORK_LOG_UPDATE,
        payload: {
          token: token,
          deviceId: deviceId,
          issueType: issueType,
          checked: checked,
        },
      });
    },
    fetchDashboard: (token: string) => {
      dispatch({
        type: TYPES.FETCH_DASHBOARD,
        payload: {
          token: token,
        },
      });
    },
  };
};

const mapStateToProps = (state: any) => {
  return {
    dashboard: state.admin.dashboard,
    token: state.token.key,
    selectedGroup: state.groups.selectedGroupId,
  };
};

const nonEmptyString = (val: any) =>
  val !== undefined && val !== null && val !== "";

interface DashboardProps {
  token: string;
  selectedGroup: string;
  dashboard?: {
    offline: DeviceType[];
    faultMode: DeviceType[];
  };
  updateDashboardWorkLog: (
    token: string,
    deviceId: string,
    issueType: string,
    checked: boolean
  ) => void;
}

const DashboardConnected = (props: DashboardProps) => {
  // Scopes get funky with anonymous functions
  const { token, selectedGroup, dashboard, updateDashboardWorkLog } = props;
  const groupHierarchies = useSelector(groupHierarchiesSelector);
  const dispatch = useDispatch();

  const [offlineSort, setOfflineSort] = useState<SortingRule<object>[]>([
    { id: "serialNumber", desc: false }, // Default sort
  ]);

  const [faultSort, setFaultSort] = useState<SortingRule<object>[]>([
    { id: "serialNumber", desc: false }, // Default sort
  ]);

  useEffect(() => {
    dispatch({
      type: TYPES.FETCH_DASHBOARD,
      payload: {
        token: token,
      },
    });
  }, []);

  const handleOfflineSortChanged = (sort: SortingRule<object>[]) => {
    setOfflineSort(sort);
  };

  const handleFaultSortChanged = (sort: SortingRule<object>[]) => {
    setFaultSort(sort);
  };

  const offlineTableData = useMemo(() => {
    return (
      dashboard?.offline.filter((device: any) => {
        const parentGroupIds =
          getParentChain(groupHierarchies, device.ownerGroupId)?.map(
            (group) => group.groupId
          ) || [];
        const groupIds = [...parentGroupIds, device.ownerGroupId];
        return groupIds.includes(selectedGroup) || !selectedGroup;
      }) || []
    );
  }, [dashboard?.offline, selectedGroup, groupHierarchies]);

  const offlineTableColumns = useMemo(() => {
    return [
      {
        Header: "In the works",
        id: "inTheWorks",
        width: 0.5,
        accessor: ({ inTheWorks }: any) => inTheWorks.toString(),
        Cell: ({ row }: any) => {
          return (
            <input
              type="checkbox"
              name="inTheWorks"
              checked={row.original.inTheWorks}
              onChange={(e) => {
                updateDashboardWorkLog(
                  token,
                  row.original.id,
                  "offline",
                  e.target.checked
                );
              }}
            />
          );
        },
      },
      {
        Header: "Serial Number",
        accessor: "serialNumber",
        width: 1.5,
      },
      {
        Header: "Location",
        accessor: ({ site, city }: any) =>
          [site, city].filter(nonEmptyString).join(", "),
        width: 2.5,
      },
      {
        Header: "Last online",
        accessor: ({ lastOnline }: any) => formatISO9075(parseISO(lastOnline)),
        width: 3,
      },
    ];
  }, [token, updateDashboardWorkLog]);

  const faultTableData = useMemo(() => {
    return (
      dashboard?.faultMode.filter((device: any) => {
        const parentGroupIds =
          getParentChain(groupHierarchies, device.ownerGroupId)?.map(
            (group) => group.groupId
          ) || [];
        const groupIds = [...parentGroupIds, device.ownerGroupId];
        return groupIds.includes(selectedGroup) || !selectedGroup;
      }) || []
    );
  }, [dashboard?.faultMode, groupHierarchies, selectedGroup]);

  const faultTableColumns = useMemo((): TableSettings["columns"] => {
    return [
      {
        Header: "In the works",
        id: "inTheWorks",
        width: 0.5,
        accessor: ({ inTheWorks }: any) => inTheWorks.toString(),
        Cell: ({ row }: any) => {
          return (
            <input
              type="checkbox"
              name="inTheWorks"
              checked={row.original.inTheWorks}
              onChange={(e) => {
                updateDashboardWorkLog(
                  token,
                  row.original.id,
                  "faultMode",
                  e.target.checked
                );
              }}
            />
          );
        },
      },
      {
        Header: "Serial Number",
        accessor: "serialNumber",
        width: 1.5,
      },
      {
        Header: "Location",
        accessor: ({ site, city }: any) =>
          [site, city].filter(nonEmptyString).join(", "),
        width: 2.5,
      },
      {
        Header: "Fault",
        accessor: ({ errorStrings }: any) => errorStrings.join(", "),
        width: 4,
      },
      {
        Header: "Online/Offline",
        accessor: ({ onlineNow }: any) => (onlineNow ? "Online" : "Offline"),
        width: 3,
      },
    ];
  }, [token, updateDashboardWorkLog]);

  return (
    <ScalePage>
      <div>
        <TableContainer>
          <Heading>Offline (over 7 days)</Heading>
          <Table
            data={offlineTableData}
            columns={offlineTableColumns}
            initialState={{
              sortBy: offlineSort,
            }}
            enableFilters={true}
            infiniteScroll={true}
            sortable={true}
            onSortingChange={(sort: any) => handleOfflineSortChanged(sort)}
          />
          <Heading>Fault modes</Heading>
          <Table
            data={faultTableData}
            columns={faultTableColumns}
            initialState={{
              sortBy: faultSort,
            }}
            enableFilters={true}
            infiniteScroll={true}
            sortable={true}
            onSortingChange={(sort: any) => handleFaultSortChanged(sort)}
          />
        </TableContainer>
      </div>
    </ScalePage>
  );
};

export const Dashboard = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardConnected);
