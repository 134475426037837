import ReactDOM from "react-dom";

import "chartjs-adapter-date-fns";
import Root from "./containers/Root";
import store from "./store/configureStore";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import "./i18n";

// Unregister the data label plugin which registers itself globally by default
// It can be enabled by passing `plugins={{[ChartDataLabels]}}` prop to any chartjs component
// Chart.plugins.unregister(ChartDataLabels);

ReactDOM.render(<Root store={store} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
