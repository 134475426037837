import { FunctionComponent, useState, useEffect, useRef } from "react";
import { Formik, FormikProps, Form, Field, ErrorMessage } from "formik";
import styled from "styled-components";
import { Header } from "./Header";
import { FlexBoxWrapper, Label } from "./Common";

import "../css/login.css";
import { FormSubmitButton } from "./Common/FormSubmitButton";
import { PrivacyStatementModal } from "./PrivacyStatementModal";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface PageProperties {
  cursor?: string;
}
const SignInPage = styled.section<PageProperties>`
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  height: 100vh;
  width: 100%;
  z-index: 1;
  cursor: ${(props) => (props.cursor ? props.cursor : "auto")};
`;

const Background = styled.div`
  flex: 1 0 auto;
  height: 100%;
  justify-content: center;
  align-items: center;
  -ms-flex: 1 0 auto;
  -ms-flex-pack: center;
  -ms-flex-align: center;
`;

const Rectangle = styled.section`
  width: 96%;
  background-color: var(--col-214269);
  border-radius: 2px;
  box-shadow: 0 0px 20px 0px rgba(0, 0, 0, 0.203);
  margin-left: auto;
  margin-right: auto;
  min-height: 42%;
  padding-bottom: 8px;
  @media (min-width: 768px) {
    max-width: 430px;
    min-width: 418px;
    min-width: 430px;
    min-height: 418px;
  }
`;

const Title = styled.div`
  padding: 20px 0;
  background-color: var(--seafoam-blue);
  border-radius: 2px;
  box-shadow: 0 0px 20px 0px rgba(0, 0, 0, 0.203);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleText = styled.div`
  font-family: "Roboto";
  font-size: 28px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: var(--col-214269);
`;

const FormLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto";
  color: #ffffff;
  height: 16px;
  width: 140px;
  position: relative;
  margin-left: 13%;
  margin-bottom: 1%;
`;
const InputBox = styled.div`
  position: relative;
  margin-top: 5%;
`;
const PrivacyStatement = styled.span`
  cursor: pointer;
`;
const OTPMessage = styled.div`
  color: white;
  margin: 0 24px;
  padding: 0 0 24px 0;

  > p {
    margin: 10px 0 0;
    padding: 0;
  }
`;
const OTPSupport = styled.p`
  color: white;
  font-size: 12px;
  margin: 24px;
`;
const ResendCodeButton = styled.button<{ disabled: boolean }>`
  font-family: "Poppins";
  font-size: 16px;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  color: ${(props) => (props.disabled ? "#777777" : "var(--seafoam-blue)")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  display: inline-block;
`;
interface LoginComponentProps {
  validate: any; // TODO: Should be some kind of function type
  onSubmit: any;
  submit: boolean;
  showOTP: boolean;
  resendOTP: (values: any) => void;
}

const LoginComponent: FunctionComponent<LoginComponentProps> = ({
  validate,
  onSubmit,
  submit,
  showOTP,
  resendOTP,
}) => {
  const initialResendTimer = 30;
  const [privacyStatementOpen, setPrivacyStatementOpen] = useState(false);
  const { t } = useTranslation();
  const [resendTimer, setResendTimer] = useState(initialResendTimer);
  const resendTimerRef = useRef<number | undefined>();

  useEffect(() => {
    // Resets the OTP field whenever the OTP input is shown or hidden
    if (formRef.current) {
      formRef.current.setFieldValue("otp", "");
    }
  }, [showOTP]);

  useEffect(() => {
    if (showOTP && !resendTimerRef.current) {
      // A new OTP is generated every 30s, so wait at least that long.
      resendTimerRef.current = window.setInterval(() => {
        if (resendTimer > 0) {
          setResendTimer((resendTimer) => resendTimer - 1);
        }
      }, 1000);
    }
  }, [resendTimer, showOTP]);

  useEffect(() => {
    return () => window.clearInterval(resendTimerRef.current);
  }, []);

  const handleResendOtp = (values: any) => {
    resendOTP(values);
    setResendTimer(initialResendTimer);
  };

  const formRef = useRef<
    FormikProps<{
      email: string;
      password: string;
      otp: string;
    }>
  >(null);

  return (
    <SignInPage cursor={submit ? "wait" : undefined}>
      <Header />
      <Background>
        <div className="login-background-image">
          <Rectangle>
            <Title>
              <TitleText>{t("login.title", "Login")}</TitleText>
            </Title>
            <Formik
              initialValues={{ email: "", password: "", otp: "" }}
              validate={validate}
              onSubmit={onSubmit}
              innerRef={formRef}
            >
              {({ values }) => (
                <Form>
                  <div className="login">
                    <InputBox hidden={showOTP}>
                      <FormLabel>{t("login.email", "Email")}</FormLabel>
                      <Field type="email" name="email" />
                      <ErrorMessage name="email" component="div" />
                    </InputBox>
                    <InputBox hidden={showOTP}>
                      <FormLabel>{t("login.password", "Password")}</FormLabel>
                      <Field type="password" name="password" />
                      <ErrorMessage name="password" component="div" />
                    </InputBox>

                    {showOTP && (
                      <InputBox>
                        <OTPMessage>
                          <strong>
                            {t(
                              "login.mfa.title",
                              "Multi-factor authentication"
                            )}
                          </strong>
                          <br />
                          <p>
                            {t(
                              "login.mfa.info",
                              "A one-time password has been sent to your e-mail address."
                            )}
                          </p>
                          <p>
                            <ResendCodeButton
                              type="button"
                              onClick={() => handleResendOtp(values)}
                              disabled={resendTimer > 0}
                              onKeyDown={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }} // Prevents this button from being pressed when the user presses enter
                            >
                              {t(
                                "login.mfa.resend",
                                "Click here to request a new code"
                              )}{" "}
                              {resendTimer > 0 ? `(${resendTimer})` : ""}
                            </ResendCodeButton>
                          </p>
                          <p>
                            {t(
                              "login.mfa.label",
                              "Enter the 6-digit code below to log in:"
                            )}
                          </p>
                        </OTPMessage>
                        <FormLabel>
                          {t("login.mfa.otp", "One-time password")}
                        </FormLabel>
                        <Field
                          // This field cannot be of password type, because browsers will then assume this is the user's main password.
                          style={{ webkitTextSecurity: "disc" }} // Non-standard, might not work on all browsers...
                          type="text"
                          name="otp"
                          autocomplete="one-time-code" // also non-standard, might not work everywhere.
                        />
                        <ErrorMessage name="otp" component="div" />
                      </InputBox>
                    )}

                    <FlexBoxWrapper marginTop={20}>
                      <FormSubmitButton
                        type="submit"
                        disabled={
                          submit || (showOTP && values.otp.length === 0)
                        }
                        className="login"
                        cursor={submit ? "wait" : undefined}
                      >
                        {t("login.submitButton", "Login")}
                      </FormSubmitButton>
                    </FlexBoxWrapper>

                    {showOTP && (
                      <OTPSupport>
                        {t(
                          "login.mfa.support",
                          "If you are not receiving your one-time passwords, please contact support.smart@europress.fi."
                        )}
                      </OTPSupport>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </Rectangle>

          <PrivacyStatementModal
            isOpen={privacyStatementOpen}
            handleClose={() => setPrivacyStatementOpen(false)}
          />

          <Label
            color={"var(--col-214269);"}
            width={100}
            height={4}
            marginTop={85}
            fontSize={14}
            lineHeight={2.86}
            textAlign={"center"}
          >
            <Link to="/forgotpassword" className="loginLink">
              {t("login.forgotPassword", "Forgot your password?")}
            </Link>
            <PrivacyStatement onClick={() => setPrivacyStatementOpen(true)}>
              {t("login.privacyStatement", "Privacy statement")}
            </PrivacyStatement>
          </Label>
        </div>
      </Background>
    </SignInPage>
  );
};

export const Login = LoginComponent;
