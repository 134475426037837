import { useState } from "react";
import styled from "styled-components";
import { GoogleMapMarkerProps } from "./GoogleMap";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 150px;
  height: 18px;
  border-radius: 8px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-color: var(--col-214269);
`;

interface RouteCircleProps {
  width?: number;
  height?: number;
  fontSize?: number;
  toggled?: boolean;
  marginLeft?: number;
}
const RouteCircle = styled.div<RouteCircleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 100%;
  font-family: Roboto;
  font-size: ${(props) => (props.fontSize ? props.fontSize : 24)}px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 0)}px;
  color: #fafbfc;
  width: ${(props) => (props.width ? props.width : 72)}px;
  height: ${(props) => (props.height ? props.height : 72)}px;
  background-color: ${(props) =>
    props.toggled ? "white" : "var(--col-214269)"};
  text-align: center;
  &:hover {
    z-index: 1;
  }
`;

const TextLabel = styled.div`
  color: white;
  font-size: 12px;
  font-weight: normal;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-left: 3px;
  margin-top: 1px;
`;

interface RouteMarkerProps extends GoogleMapMarkerProps {
  date: Date;
}

export function RouteMarker(props: RouteMarkerProps) {
  const [showTimeLabel, setShowTimeLabel] = useState(false);

  const handleClick = (): void => {
    setShowTimeLabel(!showTimeLabel);
  };

  if (showTimeLabel) {
    return (
      <Container>
        <RouteCircle
          width={12}
          height={12}
          fontSize={12}
          toggled={showTimeLabel}
          onClick={handleClick}
          marginLeft={2}
        />
        <TextLabel>{new Date(props.date).toLocaleString()}</TextLabel>
      </Container>
    );
  }

  return (
    <RouteCircle
      width={12}
      height={12}
      fontSize={12}
      toggled={showTimeLabel}
      onClick={handleClick}
    />
  );
}
