import { Component, Fragment } from "react";
import styled from "styled-components";
import * as TYPES from "../../constants/actionTypes";
import { connect } from "react-redux";
import { Formik } from "formik";
import { FormTitle } from "../../components/Common/FormTitle";
import {
  FormInput,
  FormPasswordInput,
} from "../../components/Common/FormInput";
import { FormSubmitButton } from "../../components/Common/FormSubmitButton";
import { FormBackground } from "../../components/Common/FormBackground";
import { FormRectangle } from "../../components/Common/FormRectangle";
import { FlexBoxWrapper } from "../../components/Common";
import RegisterSchema from "./RegisterSchema";
import { FormWrapper } from "../../components/Common/FormWrapper";
import { Header } from "../../components/Header";
import { PrivacyStatementModal } from "../../components/PrivacyStatementModal";
import { withTranslation } from "react-i18next";
import { getValidationErrorText } from "../../utils/i18nUtils";

const PrivacyStatement = styled.span`
  cursor: pointer;
`;

export class RegisterConnected extends Component<any, any> {
  private initialValues: {
    name: string;
    password: string;
    passwordConfirm: string;
    firstName: string;
    lastName: string;
    companyName: string;
    privacyStatement: boolean;
  };

  constructor(props: any) {
    super(props);
    this.state = { privacyStatementOpen: false };
    this.initialValues = {
      name: "",
      password: "",
      passwordConfirm: "",
      firstName: "",
      lastName: "",
      companyName: "",
      privacyStatement: false,
    };
  }

  public componentDidMount(): void {
    this.props.logout();
    const token = this.props.location.search.split("token=")[1];
    this.props.fetchUserForRegistration(token);
  }

  public render(): JSX.Element {
    const { name } = this.props;
    if (name) {
      this.initialValues = { ...this.initialValues, name };
    }

    const { t } = this.props;

    return (
      <Fragment>
        <Header />
        <FormBackground cursor={this.props.loading ? "wait" : undefined}>
          <FormRectangle>
            <FormTitle title={t("register.title", "Register")} />
            <Formik
              enableReinitialize={true}
              initialValues={this.initialValues}
              onSubmit={this.onSubmit}
              render={this.onRender}
              validationSchema={RegisterSchema}
            />
          </FormRectangle>
        </FormBackground>
        <PrivacyStatementModal
          isOpen={this.state.privacyStatementOpen}
          handleClose={() =>
            this.setState({
              privacyStatementOpen: false,
            })
          }
        />
      </Fragment>
    );
  }

  private onSubmit = (values: any) => {
    const token = this.props.location.search.split("token=")[1];
    this.props.registerUser(values, token);
  };

  private onRender = (formikProps: any) => {
    const {
      errors,
      touched,
      values,
      handleBlur,
      handleChange,
      isValid,
    } = formikProps;

    const { t } = this.props;

    return (
      <FormWrapper>
        <FormInput
          id="name"
          type="email"
          label={t("register.user", "User")}
          error={touched.name && getValidationErrorText(errors.name)}
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={true}
        />
        <FormPasswordInput
          id="password"
          label={t("register.password", "Password")}
          error={touched.password && getValidationErrorText(errors.password)}
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          required={true}
        />
        <FormPasswordInput
          id="passwordConfirm"
          label={t("register.confirmPassword", "Confirm password")}
          error={
            touched.passwordConfirm &&
            getValidationErrorText(errors.passwordConfirm)
          }
          value={values.passwordConfirm}
          onChange={handleChange}
          onBlur={handleBlur}
          required={true}
        />
        <FormInput
          id="firstName"
          type="text"
          label={t("register.firstName", "First name")}
          error={touched.firstName && getValidationErrorText(errors.firstName)}
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          required={true}
        />
        <FormInput
          id="lastName"
          type="text"
          label={t("register.lastName", "Last name")}
          error={touched.lastName && getValidationErrorText(errors.lastName)}
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          required={true}
        />
        <FormInput
          id="companyName"
          type="text"
          label={t("register.companyName", "Company name")}
          error={
            touched.companyName && getValidationErrorText(errors.companyName)
          }
          value={values.companyName}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <FormInput
          id="privacyStatement"
          type="checkbox"
          label={
            <span>
              {t("register.privacyStatement", "I have read and agree to")}
              &nbsp;
              <PrivacyStatement
                onClick={() =>
                  this.setState({
                    privacyStatementOpen: true,
                  })
                }
              >
                <u>{t("register.privacyStatementLink", "Privacy Statement")}</u>
              </PrivacyStatement>
            </span>
          }
          error={
            touched.privacyStatement &&
            getValidationErrorText(errors.privacyStatement)
          }
          value={values.privacyStatement}
          onChange={handleChange}
          onBlur={handleBlur}
          required={true}
        />
        <FlexBoxWrapper marginTop={20}>
          <FormSubmitButton
            type="submit"
            disabled={this.props.loading || !isValid}
            cursor={this.props.loading ? "wait" : undefined}
          >
            {t("register.submit", "Submit")}
          </FormSubmitButton>
        </FlexBoxWrapper>
      </FormWrapper>
    );
  };
}

const mapStateToProps = (state: any) => {
  return {
    name: state.register.name,
    loading: state.register.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchUserForRegistration: (token: string) => {
      dispatch({
        type: TYPES.FETCH_USER_FOR_REGISTRATION,
        payload: { token },
      });
    },
    registerUser: (data: object, token: string) => {
      dispatch({
        type: TYPES.REGISTER_USER,
        payload: { token, data },
      });
    },
    logout: () => {
      dispatch({
        type: TYPES.LOGOUT,
      });
    },
  };
};

export const Register = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(RegisterConnected));
