import { call, put } from "redux-saga/effects";
import * as userApi from "../api/users";
import * as TYPES from "../constants/actionTypes";
import { Notify } from "../components/Common/Notify";
import { getNotificationByAction } from "../utils/utils";
import { push } from "redux-first-history";

/**
 * Fetch user action. IF fetch fails puts action FETCH_USER_FAILURE. For success passes data
 * through FETCH_USER_SUCCESS action.
 * @export
 * @param {*} action
 */
export function* fetchUser(action: any) {
  try {
    const result = yield call(userApi.getUser, action);
    yield put({ type: TYPES.FETCH_USER_SUCCESS, payload: result.data });
  } catch (err) {
    // A quick-and-dirty way to redirect users to root if they receive a 403 response

    // This same logic should be applied to all requests; for now it is only here because most
    // customer-facing views call this endpoint
    if (err?.response?.status === 403) {
      yield put({ type: TYPES.SET_TOKEN_EXPIRED });
    } else if (err?.response?.status >= 500) {
      Notify.error(getNotificationByAction(TYPES.COMMUNICATION_ERROR), {
        detail: `${err.config.method} ${err.config.url}: ${err.message}`,
      });
    }

    yield put({
      type: TYPES.FETCH_USER_FAILURE,
      payload: { error: err?.response?.data },
    });
  }
}

export function* fetchUserForRegistration(action: any) {
  try {
    const result = yield call(userApi.getUserForRegistration, action);
    yield put({
      type: TYPES.FETCH_USER_FOR_REGISTRATION_SUCCESS,
      payload: result,
    });
  } catch (err) {
    yield put({ type: TYPES.FETCH_USER_FOR_REGISTRATION_FAILURE });
    yield put(push({ pathname: "/" }));
    Notify.error(
      getNotificationByAction(TYPES.FETCH_USER_FOR_REGISTRATION_FAILURE)
    );
  }
}

export function* registerUser(action: any) {
  try {
    yield call(userApi.registerUser, action);
    yield put({
      type: TYPES.REGISTER_USER_SUCCESS,
    });
    Notify.success(TYPES.REGISTER_USER_SUCCESS);
    yield put(push({ pathname: "/" }));
  } catch (err) {
    yield put({ type: TYPES.REGISTER_USER_FAILURE });
    Notify.error(getNotificationByAction(TYPES.REGISTER_USER_FAILURE), {
      detail: err?.response?.data?.error,
    });
  }
}

export function* fetchUsersForAdmin(action: any) {
  try {
    const result = yield call(userApi.getUsersForAdmin, action);
    yield put({
      type: TYPES.FETCH_USERS_FOR_ADMIN_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    yield put({ type: TYPES.FETCH_USERS_FOR_ADMIN_FAILURE });
  }
}

export function* fetchUserForAdmin(action: any) {
  try {
    const result = yield call(userApi.getUserForAdmin, action);
    yield put({
      type: TYPES.FETCH_USER_FOR_ADMIN_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    yield put({ type: TYPES.FETCH_USER_FOR_ADMIN_FAILURE });
  }
}

export function* sendRegisterLinkToUser(action: any) {
  try {
    const result = yield call(userApi.sendLinkToUser, action);
    yield put({
      type: TYPES.SEND_REGISTER_LINK_TO_USER_SUCCESS,
      payload: result.data,
    });
    Notify.success(
      getNotificationByAction(TYPES.SEND_REGISTER_LINK_TO_USER_SUCCESS)
    );
  } catch (err) {
    yield put({ type: TYPES.SEND_REGISTER_LINK_TO_USER_FAILURE });
    Notify.error(
      getNotificationByAction(TYPES.SEND_REGISTER_LINK_TO_USER_FAILURE)
    );
  }
}

export function* createUserForAdmin(action: any) {
  try {
    const result = yield call(userApi.postUserForAdmin, action);
    yield put({
      type: TYPES.CREATE_USER_FOR_ADMIN_SUCCESS,
      payload: result.data,
    });
    Notify.success(
      getNotificationByAction(TYPES.CREATE_USER_FOR_ADMIN_SUCCESS)
    );
    yield put(push({ pathname: "/admin" }));
  } catch (err) {
    yield put({ type: TYPES.CREATE_USER_FOR_ADMIN_FAILURE });
    Notify.error(getNotificationByAction(TYPES.CREATE_USER_FOR_ADMIN_FAILURE), {
      detail: err?.response?.data?.error,
    });
  }
}

export function* updateUserForAdmin(action: any) {
  try {
    const result = yield call(userApi.patchUserForAdmin, action);
    yield put({
      type: TYPES.UPDATE_USER_FOR_ADMIN_SUCCESS,
      payload: result.data,
    });
    yield put(push({ pathname: "/admin" }));
    Notify.success(
      getNotificationByAction(TYPES.UPDATE_USER_FOR_ADMIN_SUCCESS)
    );
  } catch (err) {
    yield put({
      type: TYPES.UPDATE_USER_FOR_ADMIN_FAILURE,
    });
    if (err.response.status === 422) {
      Notify.error(
        getNotificationByAction(TYPES.UPDATE_USER_FOR_ADMIN_FAILURE_NAME_EXISTS)
      );
    } else {
      Notify.error(
        getNotificationByAction(TYPES.UPDATE_USER_FOR_ADMIN_FAILURE),
        { detail: err?.response?.data?.error }
      );
    }
  }
}

export function* deleteUserForAdmin(action: TYPES.DeleteUserAction) {
  try {
    yield call(userApi.deleteUserForAdmin, action);
    yield put({
      type: TYPES.DELETE_USER_FOR_ADMIN_SUCCESS,
      payload: { userId: action.payload.userId },
    });
    Notify.success(
      getNotificationByAction(TYPES.DELETE_USER_FOR_ADMIN_SUCCESS)
    );
  } catch (err) {
    yield put({ type: TYPES.DELETE_USER_FOR_ADMIN_FAILURE });
    Notify.error(getNotificationByAction(TYPES.DELETE_USER_FOR_ADMIN_FAILURE), {
      detail: err?.response?.data?.error,
    });
  }
}

export function* disableUserForAdmin(action: TYPES.DeleteUserAction) {
  try {
    yield call(userApi.disableUserForAdmin, action);
    yield put({
      type: TYPES.DISABLE_USER_FOR_ADMIN_SUCCESS,
      payload: { userId: action.payload.userId },
    });
    Notify.success(
      getNotificationByAction(TYPES.DISABLE_USER_FOR_ADMIN_SUCCESS)
    );
  } catch (err) {
    yield put({ type: TYPES.DISABLE_USER_FOR_ADMIN_FAILURE });
    Notify.error(
      getNotificationByAction(TYPES.DISABLE_USER_FOR_ADMIN_FAILURE),
      {
        detail: err?.response?.data?.error,
      }
    );
  }
}

export function* enableMFAForAdmin(action: TYPES.EnableMFAForAdminAction) {
  try {
    yield call(userApi.enableMFAForAdmin, action);
    yield put({
      type: TYPES.ENABLE_USER_MFA_FOR_ADMIN_SUCCESS,
      payload: { userId: action.payload.userId },
    });
    Notify.success(
      getNotificationByAction(TYPES.ENABLE_USER_MFA_FOR_ADMIN_SUCCESS)
    );
  } catch (err) {
    yield put({ type: TYPES.ENABLE_USER_MFA_FOR_ADMIN_FAILURE });
    Notify.error(
      getNotificationByAction(TYPES.ENABLE_USER_MFA_FOR_ADMIN_FAILURE),
      {
        detail: err?.response?.data?.error,
      }
    );
  }
}

export function* disableMFAForAdmin(action: any) {
  try {
    yield call(userApi.disableMFAForAdmin, action);
    yield put({
      type: TYPES.DISABLE_USER_MFA_FOR_ADMIN_SUCCESS,
      payload: { userId: action.payload.userId },
    });
    Notify.success(
      getNotificationByAction(TYPES.DISABLE_USER_MFA_FOR_ADMIN_SUCCESS)
    );
  } catch (err) {
    yield put({ type: TYPES.DISABLE_USER_MFA_FOR_ADMIN_FAILURE });
    Notify.error(
      getNotificationByAction(TYPES.DISABLE_USER_MFA_FOR_ADMIN_FAILURE),
      {
        detail: err?.response?.data?.error,
      }
    );
  }
}

export function* postDeviceColumnsForUser(action: any) {
  try {
    const result = yield call(userApi.postColumnsForUser, action);
    yield put({
      type: TYPES.DEVICES_COLUMNS_SELECTED_SUCCESS,
      payload: { user: result.data },
    });
  } catch (err) {
    yield put({ type: TYPES.DEVICES_COLUMNS_SELECTED_FAILURE });
  }
}

export function* postSkipColumnsForUser(action: any) {
  try {
    const result = yield call(userApi.postColumnsForUser, action);
    yield put({
      type: TYPES.SKIP_COLUMNS_SELECTED_SUCCESS,
      payload: { user: result.data },
    });
  } catch (err) {
    yield put({ type: TYPES.SKIP_COLUMNS_SELECTED_FAILURE });
  }
}
export function* loginToServiceTool(action: any) {
  try {
    yield call(userApi.postServiceToolLogin, action);
    yield put({ type: TYPES.DO_SERVICE_TOOL_LOGIN_SUCCESS });
    yield put(push({ pathname: "/servicetool/login/success" }));
  } catch (err) {
    yield put({ type: TYPES.DO_SERVICE_TOOL_LOGIN_FAILURE });
    Notify.error(getNotificationByAction(TYPES.DEFAULT_ERROR), {
      detail: err?.response?.data?.error,
    });
  }
}

export function* resetPassword(action: any) {
  try {
    yield call(userApi.resetPasswordForUser, action);
    yield put(push({ pathname: "/" }));
    Notify.success(getNotificationByAction(TYPES.RESET_PASSWORD_SUCCESS));
  } catch (err) {
    Notify.error(getNotificationByAction(TYPES.RESET_PASSWORD_FAILURE), {
      detail: err?.response?.data?.error,
    });
  }
}

export function* forgotPassword(action: any) {
  try {
    yield call(userApi.forgotPasswordForUser, action);
    Notify.success(getNotificationByAction(TYPES.FORGOT_PASSWORD_SUCCESS));
  } catch (err) {} // <-- Huh?
}

export function* verifyResetPasswordToken(action: any) {
  try {
    yield call(userApi.verifyResetPasswordToken, action);
  } catch (err) {
    yield put(push({ pathname: "/" }));
    Notify.error(
      getNotificationByAction(TYPES.VERIFY_RESET_PASSWORD_TOKEN_FAILURE),
      {
        detail: err?.response?.data?.error,
      }
    );
  }
}

export function* changeLanguage(action: any) {
  try {
    const result = yield call(userApi.postLanguageForUser, action);
    yield put({
      type: TYPES.CHANGE_LANGUAGE_SUCCESS,
      payload: result,
    });
  } catch (err) {
    yield put({ type: TYPES.CHANGE_LANGUAGE_FAILURE });
  }
}

export function* verifyMFAActionForUser(action: any) {
  try {
    const result = yield call(userApi.verifyMFAActionForUser, action);
    yield put({
      type: TYPES.VERIFY_MFA_ACTION_SUCCESS,
      payload: result,
    });
  } catch (err) {
    yield put({ type: TYPES.VERIFY_MFA_ACTION_FAILURE });
    Notify.error(getNotificationByAction(TYPES.VERIFY_MFA_ACTION_FAILURE));
  }
}

export function* enableMFAForUser(action: any) {
  try {
    const result = yield call(userApi.enableMFAForUser, action);
    yield put({
      type: TYPES.ENABLE_MFA_FOR_USER_SUCCESS,
      payload: result,
    });
    Notify.success(getNotificationByAction(TYPES.ENABLE_MFA_FOR_USER_SUCCESS));
  } catch (err) {
    yield put({ type: TYPES.ENABLE_MFA_FOR_USER_FAILURE });
    Notify.error(getNotificationByAction(TYPES.ENABLE_MFA_FOR_USER_FAILURE));
  }
}

export function* disableMFAForUser(action: any) {
  try {
    const result = yield call(userApi.disableMFAForUser, action);
    yield put({
      type: TYPES.DISABLE_MFA_FOR_USER_SUCCESS,
      payload: result,
    });
    Notify.success(getNotificationByAction(TYPES.DISABLE_MFA_FOR_USER_SUCCESS));
  } catch (err) {
    yield put({ type: TYPES.DISABLE_MFA_FOR_USER_FAILURE });
    Notify.error(getNotificationByAction(TYPES.DISABLE_MFA_FOR_USER_FAILURE));
  }
}

export function* requestUserDeletion(action: any) {
  try {
    const result = yield call(userApi.requestUserDeletion, action);
    yield put({
      type: TYPES.REQUEST_USER_DELETION_SUCCESS,
      payload: result,
    });
    Notify.success(
      getNotificationByAction(TYPES.REQUEST_USER_DELETION_SUCCESS)
    );
  } catch (err) {
    yield put({ type: TYPES.REQUEST_USER_DELETION_FAILURE, payload: err });
    Notify.error(getNotificationByAction(TYPES.REQUEST_USER_DELETION_FAILURE));
  }
}
