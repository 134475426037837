import { useEffect } from "react";
import * as TYPES from "../../../constants/actionTypes";

import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import { FormInput } from "../../../components/Common/FormInput";
import { FormRectangle } from "../../../components/Common/FormRectangle";
import { FormBackground } from "../../../components/Common/FormBackground";
import { FormSubmitButton } from "../../../components/Common/FormSubmitButton";
import { FlexBoxWrapper } from "../../../components/Common";
import { FormTitle } from "../../../components/Common/FormTitle";
import RetrofitLightDeviceSchema from "./RetrofitLightDeviceSchema";
import { FormWrapper } from "../../../components/Common/FormWrapper";
import {
  adminRetrofitLightDevicesSelector,
  adminSubmittingSelector,
  isRetrofitLightDevicesLoadingSelector,
} from "../../../selectors/admin";
import { GroupSelect } from "../GroupSelect";
import { groupHierarchiesSelector } from "../../../selectors/groups";
import { FillLevel, RetrofitLightInputModes } from "@shared/types";
import { getTimeZone } from "@shared/time";
import { Divider } from "../../../components/Common/Divider";
import { InfoText, EmailAlertEditor } from "./DeviceEditElements";

type FormValues = {
  retrofitLightDeviceId: string;
  serialNumber: string;
  groupId: number;
  wasteFraction: string;
  productType: string;
  site: string;
  city: string;
  address: string;
  fillLevel: string;
  weekdays: number[];
  startHour: number;
  endHour: number;
  resendHours: number | null | "";
  timeZone: string;
  emptyingMsg: boolean;
  recipients: string[];
  digitalInput1: string;
  digitalInput2: string;
  digitalInput3: string;
  digitalInput1ContactType: string;
  digitalInput2ContactType: string;
  digitalInput3ContactType: string;
};

type RetrofitLightDeviceFormProps = {
  values: FormValues;
  onSubmit: (values: FormValues) => void;
  isSubmitting: boolean;
  title: string;
  nameDisabled?: boolean;
};

const inputOptions = [
  {
    value: RetrofitLightInputModes.ALMOST_FULL,
    text: RetrofitLightInputModes.ALMOST_FULL,
  },
  {
    value: RetrofitLightInputModes.CONTAINER_NOT_PRESENT,
    text: RetrofitLightInputModes.CONTAINER_NOT_PRESENT,
  },
  {
    value: RetrofitLightInputModes.EMERGENCY_STOP_ACTIVATED,
    text: RetrofitLightInputModes.EMERGENCY_STOP_ACTIVATED,
  },
  {
    value: RetrofitLightInputModes.FULL,
    text: RetrofitLightInputModes.FULL,
  },
  {
    value: RetrofitLightInputModes.OIL_LEVEL_LOW,
    text: RetrofitLightInputModes.OIL_LEVEL_LOW,
  },
  {
    value: RetrofitLightInputModes.SAFETY_FUNCTION_ACTIVE,
    text: RetrofitLightInputModes.SAFETY_FUNCTION_ACTIVE,
  },
];

const inputContactTypeOptions = [
  {
    value: "NC",
    text: "NC (Normally Closed)",
  },
  {
    value: "NO",
    text: "NO (Normally Open)",
  },
];

function RetrofitLightDeviceForm(props: RetrofitLightDeviceFormProps) {
  const groupHierarchy = useSelector(groupHierarchiesSelector);
  return (
    <FormBackground cursor={props.isSubmitting ? "wait" : undefined}>
      <FormRectangle>
        <FormTitle title={props.title} />
        <Formik<FormValues>
          initialValues={{
            ...props.values,
          }}
          onSubmit={(values, _actions) => {
            if (props.isSubmitting) {
              return;
            }

            props.onSubmit({
              retrofitLightDeviceId: values.retrofitLightDeviceId,
              groupId: values.groupId,
              serialNumber: values.serialNumber.trim(),
              wasteFraction: values.wasteFraction.trim(),
              productType: values.productType.trim(),
              site: values.site.trim(),
              city: values.city.trim(),
              address: values.address.trim(),
              fillLevel: values.fillLevel,
              weekdays: values.weekdays,
              startHour: values.startHour,
              endHour: values.endHour,
              resendHours:
                values.resendHours === "" ? null : values.resendHours,
              timeZone: values.timeZone,
              emptyingMsg: values.emptyingMsg,
              recipients: values.recipients,
              digitalInput1: values.digitalInput1,
              digitalInput2: values.digitalInput2,
              digitalInput3: values.digitalInput3,
              digitalInput1ContactType: values.digitalInput1ContactType,
              digitalInput2ContactType: values.digitalInput2ContactType,
              digitalInput3ContactType: values.digitalInput3ContactType,
            });
          }}
          validationSchema={RetrofitLightDeviceSchema}
        >
          {(formikProps) => {
            const {
              errors,
              values,
              touched,
              handleChange,
              setFieldValue,
              handleBlur,
            } = formikProps;

            return (
              <FormWrapper>
                <FormInput
                  id="retrofitLightDeviceId"
                  type="number"
                  label="Retrofit Light id"
                  error={
                    touched.retrofitLightDeviceId &&
                    errors.retrofitLightDeviceId
                      ? errors.retrofitLightDeviceId
                      : undefined
                  }
                  value={values.retrofitLightDeviceId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={props.nameDisabled}
                />
                <GroupSelect
                  id="groupId"
                  label="Owner group"
                  value={values.groupId}
                  error={errors.groupId}
                  options={groupHierarchy}
                  onChange={setFieldValue}
                  required={true}
                />
                <FormInput
                  id="serialNumber"
                  type="string"
                  label="serialNumber"
                  error={
                    touched.serialNumber && errors.serialNumber
                      ? errors.serialNumber
                      : undefined
                  }
                  value={values.serialNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={true}
                />
                <FormInput
                  id="wasteFraction"
                  type="string"
                  label="Waste Fraction"
                  error={
                    touched.wasteFraction && errors.wasteFraction
                      ? errors.wasteFraction
                      : undefined
                  }
                  value={values.wasteFraction}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormInput
                  id="productType"
                  type="string"
                  label="Product Type"
                  error={
                    touched.productType && errors.productType
                      ? errors.productType
                      : undefined
                  }
                  value={values.productType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormInput
                  id="site"
                  type="string"
                  label="Site"
                  error={touched.site && errors.site ? errors.site : undefined}
                  value={values.site}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormInput
                  id="city"
                  type="string"
                  label="City"
                  error={touched.city && errors.city ? errors.city : undefined}
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormInput
                  id="address"
                  type="string"
                  label="Address"
                  error={
                    touched.address && errors.address
                      ? errors.address
                      : undefined
                  }
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Divider />
                <InfoText>Input config</InfoText>
                <FormInput
                  id="digitalInput1"
                  type=""
                  component="select"
                  label="Digital Input 1"
                  error={
                    touched.digitalInput1 && errors.digitalInput1
                      ? errors.digitalInput1
                      : ""
                  }
                  value={values.digitalInput1}
                  options={inputOptions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
                <FormInput
                  id="digitalInput1ContactType"
                  type=""
                  component="select"
                  label="Digital Input 1 Contact Type"
                  error={
                    touched.digitalInput1ContactType &&
                    errors.digitalInput1ContactType
                      ? errors.digitalInput1ContactType
                      : ""
                  }
                  value={values.digitalInput1ContactType}
                  options={inputContactTypeOptions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
                <FormInput
                  id="digitalInput2"
                  type=""
                  component="select"
                  label="Digital Input 2"
                  error={
                    touched.digitalInput2 && errors.digitalInput2
                      ? errors.digitalInput2
                      : ""
                  }
                  value={values.digitalInput2}
                  options={inputOptions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
                <FormInput
                  id="digitalInput2ContactType"
                  type=""
                  component="select"
                  label="Digital Input 2 Contact Type"
                  error={
                    touched.digitalInput2ContactType &&
                    errors.digitalInput2ContactType
                      ? errors.digitalInput2ContactType
                      : ""
                  }
                  value={values.digitalInput2ContactType}
                  options={inputContactTypeOptions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
                <FormInput
                  id="digitalInput3"
                  type=""
                  component="select"
                  label="Digital Input 3"
                  error={
                    touched.digitalInput3 && errors.digitalInput3
                      ? errors.digitalInput3
                      : ""
                  }
                  value={values.digitalInput3}
                  options={inputOptions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
                <FormInput
                  id="digitalInput3ContactType"
                  type=""
                  component="select"
                  label="Digital Input 3 Contact Type"
                  error={
                    touched.digitalInput3ContactType &&
                    errors.digitalInput3ContactType
                      ? errors.digitalInput3ContactType
                      : ""
                  }
                  value={values.digitalInput3ContactType}
                  options={inputContactTypeOptions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
                <Divider />
                <EmailAlertEditor {...formikProps} />
                <FlexBoxWrapper marginTop={20}>
                  <Link to={`/admin/devices/device-management`}>
                    <FormSubmitButton
                      disabled={props.isSubmitting}
                      cursor={props.isSubmitting ? "wait" : undefined}
                    >
                      Cancel
                    </FormSubmitButton>
                  </Link>
                  &nbsp;
                  <FormSubmitButton
                    type="submit"
                    disabled={props.isSubmitting}
                    cursor={props.isSubmitting ? "wait" : undefined}
                  >
                    Submit
                  </FormSubmitButton>
                </FlexBoxWrapper>
              </FormWrapper>
            );
          }}
        </Formik>
      </FormRectangle>
    </FormBackground>
  );
}

export function EditRetrofitLightDeviceForm(
  props: RouteComponentProps<{ id: string }>
) {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.token.key);
  const isLoading = useSelector(isRetrofitLightDevicesLoadingSelector);
  const isSubmitting = useSelector(adminSubmittingSelector);

  const devices = useSelector(adminRetrofitLightDevicesSelector);

  useEffect(() => {
    dispatch({
      type: TYPES.GET_ALL_RETROFIT_LIGHT_DEVICES,
      payload: { token },
    });
  }, [dispatch, token]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const device = devices.find(
    (device: { retrofitLightDeviceId: string }) =>
      device.retrofitLightDeviceId === props.match.params.id
  );

  if (!device?.retrofitLightDeviceId) {
    return <div>Could not find device</div>;
  }

  return (
    <RetrofitLightDeviceForm
      title="Edit Retrofit Light device"
      values={{
        retrofitLightDeviceId: device?.retrofitLightDeviceId,
        groupId: device?.groupId,
        serialNumber: device?.serialNumber || "",
        wasteFraction: device?.wasteFraction || "",
        productType: device?.productType || "",
        site: device?.site || "",
        city: device?.city || "",
        address: device?.address || "",
        fillLevel: device?.filllevel || FillLevel.EMPTY.toString(),
        startHour: device?.startHour ?? 0,
        endHour: device?.endHour ?? 24,
        resendHours: device?.resendHours ?? null,
        timeZone: device?.timeZone || getTimeZone(),
        weekdays: device?.weekdays || [],
        emptyingMsg: device?.emptyingMsg || false,
        recipients: device?.recipients || [],
        digitalInput1:
          device?.digitalInput1 ||
          RetrofitLightInputModes.EMERGENCY_STOP_ACTIVATED,
        digitalInput2:
          device?.digitalInput2 || RetrofitLightInputModes.OIL_LEVEL_LOW,
        digitalInput3: device?.digitalInput3 || RetrofitLightInputModes.FULL,
        digitalInput1ContactType: device.digitalInput1ContactType || "NC",
        digitalInput2ContactType: device.digitalInput2ContactType || "NO",
        digitalInput3ContactType: device.digitalInput3ContactType || "NC",
      }}
      onSubmit={(values) => {
        dispatch({
          type: TYPES.PATCH_RETROFIT_LIGHT_DEVICE,
          payload: {
            retrofitLightDeviceId: device?.retrofitLightDeviceId,
            data: values,
            token,
          },
        });
      }}
      isSubmitting={isSubmitting}
      nameDisabled
    />
  );
}
