export const ErrorComponent = ({ message }: { message: string }) => {
  return (
    <div>
      <p>
        <b>Something went wrong.</b> Please try again by refreshing the page or
        clearing your browser cache.
      </p>
      <p>
        If the error persists, please contact support (
        <a href="mailto:support.smart@europress.fi">
          support.smart@europress.fi
        </a>
        ).
      </p>
      <p>
        To help with debugging the problem, provide support with the following
        details:
      </p>
      <p
        style={{
          fontFamily: "monospace",
          background: "#cfcfcf",
          padding: "4px",
          margin: "0 12px",
        }}
      >
        [@{Date.now()}] {message}
      </p>
    </div>
  );
};
