import { Divider } from "../../../components/Common/Divider";
import styled from "styled-components";
import { Field, FieldArray, FormikProps } from "formik";
import {
  CheckboxGroup,
  FormInput,
  FormLabel,
  FormToggle,
  ReadonlyFormInput,
} from "../../../components/Common/FormInput";
import { FillLevel } from "@shared/types";
import { weekdayValues } from "../../../utils/utils";
import { getTimeZone } from "@shared/time";

export const SettingsRow = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const InfoText = styled.p`
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Roboto";
  width: 75%;
  margin: 1.5rem auto;
  padding-top: 1px;
  color: white;
`;

export const RecipientsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 60px;
  padding-left: 60px;
`;

export const Recipient = styled.div`
  display: flex;
  margin-top: 5px;

  input {
    height: 22px;
    line-height: 22px;
    display: inline-flex;
    flex-grow: 1;
    border: solid 1px var(--light-blue-grey);
    border-radius: 4px;
    padding-left: 5px;
  }

  button {
    margin-left: 10px;
    height: 23px;
    width: 23px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: condensed;
    font-style: normal;
    line-height: 23px;
    letter-spacing: 0.25px;
    border-radius: 4px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    color: #0d467d;
    background-color: #ffffff;
    cursor: pointer;
    border: solid 1px rgba(0, 0, 0, 0.1);
  }
`;

export const AddRecipientButton = styled.button`
  max-width: 100px;
  margin-top: 5px;
  height: 23px;
  line-height: 23px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 10px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: 0.25px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #0d467d;
  color: #ffffff;
  cursor: pointer;
  border: solid 1px rgba(0, 0, 0, 0.1);
`;

export interface EmailAlertType {
  fillLevel?: string;
  weekdays?: number[];
  startHour?: number;
  endHour?: number;
  resendHours?: number | null | "";
  timeZone?: string;
  emptyingMsg?: boolean;
  recipients?: string[];
}

type Props = Pick<
  FormikProps<EmailAlertType>,
  | "errors"
  | "values"
  | "touched"
  | "handleBlur"
  | "handleChange"
  | "setFieldValue"
>;
export const EmailAlertEditor = ({
  errors,
  values,
  touched,
  handleChange,
  setFieldValue,
  handleBlur,
}: Props) => {
  return (
    <>
      <Divider />
      <InfoText>fill level email notification settings</InfoText>
      <FormToggle
        id="emptyingMsg"
        label="Send email when device is emptied"
        selected={values.emptyingMsg || false}
        onChange={setFieldValue}
      />
      <FormInput
        id="fillLevel"
        type=""
        component="select"
        label="Fill level"
        error={touched.fillLevel && errors.fillLevel ? errors.fillLevel : ""}
        value={values.fillLevel || null}
        options={[
          {
            value: FillLevel.EMPTY.toString(),
            text: "Empty",
          },
          {
            value: FillLevel.LEVEL_1.toString(),
            text: "Level 1",
          },
          {
            value: FillLevel.LEVEL_2.toString(),
            text: "Level 2",
          },
          {
            value: FillLevel.ALMOST_FULL.toString(),
            text: "Almost Full",
          },
          {
            value: FillLevel.FULL.toString(),
            text: "Full",
          },
          {
            value: FillLevel.OVERFULL.toString(),
            text: "Overfull",
          },
        ]}
        onChange={handleChange}
        onBlur={handleBlur}
        required
      />
      <CheckboxGroup
        id="weekdays"
        label="Weekdays"
        checkboxes={weekdayValues}
        required
        error={
          touched.weekdays && typeof errors.weekdays === "string"
            ? errors.weekdays
            : ""
        }
      />
      <ReadonlyFormInput label="Time zone" value={getTimeZone()} />
      <FormInput
        id="startHour"
        required
        type="number"
        label="Start hour (included)"
        error={touched.startHour && errors.startHour ? errors.startHour : ""}
        value={values.startHour || null}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <FormInput
        id="endHour"
        required
        type="number"
        label="End hour (not included)"
        error={touched.endHour && errors.endHour ? errors.endHour : ""}
        value={values.endHour || null}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <FormInput
        id="resendHours"
        type="number"
        label="Resend period (hours)"
        error={
          touched.resendHours && errors.resendHours ? errors.resendHours : ""
        }
        value={values.resendHours || null}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <FieldArray
        name="recipients"
        render={(arrayHelpers) => (
          <div>
            <FormLabel>{"Email Notification Recipients"}:</FormLabel>
            <SettingsRow>
              <RecipientsWrapper>
                {values.recipients && values.recipients.length > 0 ? (
                  values.recipients.map((_recipient: any, index: number) => (
                    <Recipient key={index}>
                      <Field name={`recipients.${index}`} />
                      <button
                        type="button"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        -
                      </button>
                      <button
                        type="button"
                        onClick={() => arrayHelpers.insert(index + 1, "")}
                      >
                        +
                      </button>
                    </Recipient>
                  ))
                ) : (
                  <AddRecipientButton
                    type="button"
                    onClick={() => arrayHelpers.push("")}
                  >
                    {"Add recipient"}
                  </AddRecipientButton>
                )}
              </RecipientsWrapper>
            </SettingsRow>
          </div>
        )}
      />
      <Divider />
    </>
  );
};
