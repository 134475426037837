import Axios, { AxiosPromise } from "axios";
import * as TYPES from "../constants/actionTypes";

/**
 * Fetches user data from server.
 *
 * @export
 * @returns {AxiosPromise}
 */
export function getUser(action: any): AxiosPromise {
  let request = process.env.REACT_APP_LATEST_API + "/users/";
  if (action.payload.user !== undefined) {
    request = request + action.payload.user;
  }
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 20 s
    timeout: 20000,
  };
  let response = undefined;
  try {
    response = Axios.get(request, config);
  } catch (error) {
    throw error;
  }
  return response;
}

export function getUsersForAdmin(action: any): AxiosPromise {
  const url = process.env.REACT_APP_LATEST_API + "/admin/users/";
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 20 s
    timeout: 20000,
  };
  try {
    return Axios.get(url, config);
  } catch (error) {
    return error;
  }
}

export function getUserForAdmin(action: any): AxiosPromise {
  const url =
    process.env.REACT_APP_LATEST_API + "/admin/users/" + action.payload.userId;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 20 s
    timeout: 20000,
  };
  try {
    return Axios.get(url, config);
  } catch (error) {
    return error;
  }
}

export function getUserForRegistration(action: any): AxiosPromise | Object {
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 20 s
    timeout: 20000,
  };
  const url = `${process.env.REACT_APP_LATEST_API}/register/`;
  try {
    return Axios.get(url, config);
  } catch (error) {
    return error;
  }
}

export function registerUser(action: any): AxiosPromise | Object {
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 20 s
    timeout: 20000,
  };
  const url = `${process.env.REACT_APP_LATEST_API}/register/`;
  try {
    return Axios.post(url, action.payload.data, config);
  } catch (error) {
    return error;
  }
}

export function requestUserDeletion(action: any): AxiosPromise | Object {
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  const url = `${process.env.REACT_APP_LATEST_API}/users/request-deletion/`;
  try {
    return Axios.post(url, action.payload.data, config);
  } catch (error) {
    return error;
  }
}

export function postUserForAdmin(action: any): AxiosPromise | Object {
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 60 s
    timeout: 60000,
  };
  const url = `${process.env.REACT_APP_LATEST_API}/admin/users/`;
  try {
    return Axios.post(url, action.payload.data, config);
  } catch (error) {
    return error;
  }
}

export function patchUserForAdmin(action: any): AxiosPromise | Object {
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 20 s
    timeout: 20000,
  };
  const url = `${process.env.REACT_APP_LATEST_API}/admin/users/${action.payload.userId}`;
  try {
    return Axios.patch(url, action.payload.data, config);
  } catch (error) {
    return error;
  }
}

export function deleteUserForAdmin(
  action: TYPES.DeleteUserAction
): AxiosPromise | Object {
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 60000,
  };
  const url = `${process.env.REACT_APP_LATEST_API}/admin/users/${action.payload.userId}`;
  try {
    return Axios.delete(url, config);
  } catch (error) {
    return error;
  }
}

export function disableUserForAdmin(
  action: TYPES.DeleteUserAction
): AxiosPromise | Object {
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 60000,
  };
  const url = `${process.env.REACT_APP_LATEST_API}/admin/users/${action.payload.userId}/disable`;
  try {
    return Axios.post(url, null, config);
  } catch (error) {
    return error;
  }
}

export function sendLinkToUser(action: any): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/admin/users/${action.payload.userId}/email`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 60 s
    timeout: 60000,
  };
  try {
    return Axios.post(url, null, config);
  } catch (error) {
    throw error;
  }
}

export function postColumnsForUser(action: any): AxiosPromise | Object {
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 20 s
    timeout: 20000,
  };
  const url = `${process.env.REACT_APP_LATEST_API}/users/columns`;
  try {
    return Axios.post(url, action.payload.data, config);
  } catch (error) {
    return error;
  }
}

export function postServiceToolLogin(action: any): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/users/servicetool/login`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 20 s
    timeout: 20000,
  };
  return Axios.post(url, { userCode: action.payload.userCode }, config);
}

export function resetPasswordForUser(action: any): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/password/reset`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 20 s
    timeout: 20000,
  };
  try {
    return Axios.post(url, action.payload.data, config);
  } catch (error) {
    return error;
  }
}

export function forgotPasswordForUser(action: any): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/password/forgot`;
  const config = {
    // timeout 20 s
    timeout: 20000,
  };
  try {
    return Axios.post(url, action.payload.data, config);
  } catch (error) {
    return error;
  }
}

export function verifyResetPasswordToken(action: any): AxiosPromise | Object {
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 20 s
    timeout: 20000,
  };
  const url = `${process.env.REACT_APP_LATEST_API}/password/verify`;
  try {
    return Axios.get(url, config);
  } catch (error) {
    return error;
  }
}

export function doLogout(action: any): AxiosPromise {
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 20 s
    timeout: 20000,
  };

  const url = `${process.env.REACT_APP_LATEST_API}/users/logout`;
  try {
    return Axios.post(url, action.payload.data, config);
  } catch (error) {
    return error;
  }
}

export function postLanguageForUser(action: any): AxiosPromise | Object {
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 20 s
    timeout: 20000,
  };
  const url = `${process.env.REACT_APP_LATEST_API}/users/language`;
  try {
    return Axios.post(url, action.payload.data, config);
  } catch (error) {
    return error;
  }
}

export function generateOtpSecretForUser(action: any): AxiosPromise | Object {
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  const url = `${process.env.REACT_APP_LATEST_API}/mfa/generate`;
  try {
    return Axios.post(url, action.payload.data, config);
  } catch (error) {
    return error;
  }
}

export function enableMFAForAdmin(action: any): AxiosPromise | Object {
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  const url = `${process.env.REACT_APP_LATEST_API}/admin/users/${action.payload.userId}/enable-mfa`;
  try {
    return Axios.post(url, action.payload.data, config);
  } catch (error) {
    console.log(error);
    return error;
  }
}

export function disableMFAForAdmin(action: any): AxiosPromise | Object {
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  const url = `${process.env.REACT_APP_LATEST_API}/admin/users/${action.payload.userId}/disable-mfa`;
  try {
    return Axios.post(url, action.payload.data, config);
  } catch (error) {
    console.log(error);
    return error;
  }
}

export function enableMFAForUser(action: any): AxiosPromise | Object {
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  const url = `${process.env.REACT_APP_LATEST_API}/mfa/enable`;
  try {
    return Axios.post(url, action.payload.data, config);
  } catch (error) {
    return error;
  }
}

export function disableMFAForUser(action: any): AxiosPromise | Object {
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  const url = `${process.env.REACT_APP_LATEST_API}/mfa/disable`;
  try {
    return Axios.post(url, action.payload.data, config);
  } catch (error) {
    return error;
  }
}

export function verifyMFAActionForUser(action: any): AxiosPromise | Object {
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  const url = `${process.env.REACT_APP_LATEST_API}/mfa/verify`;
  try {
    return Axios.post(url, action.payload.data, config);
  } catch (error) {
    return error;
  }
}
