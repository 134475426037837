import styled from "styled-components";

import "../css/spinner.css";

interface SpinnerProps {
  position?: string;
  top?: number;
  height?: string;
  z?: number;
}
const SpinnerParent = styled.div<SpinnerProps>`
  position: ${(props) => props.position || "fixed"};
  top: ${(props) => props.top || 0};
  left: 0;
  width: 100%;
  height: ${(props) => props.height || "100vh"};
  background: rgba(250, 250, 250, 0.5);
  z-index: ${(props) => (props.z !== undefined ? props.z : 25)};
`;

export function Spinner(props: SpinnerProps) {
  return (
    <SpinnerParent {...props}>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </SpinnerParent>
  );
}

export function SpinnerSmall({
  centerInContainer,
}: {
  centerInContainer?: boolean;
}) {
  return (
    <div
      className="lds-ring-small"
      style={
        centerInContainer
          ? {}
          : {
              top: "initial",
              left: "initial",
              transform: "initial",
              position: "relative",
            }
      }
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
