import { useEffect, useState, useMemo, useRef } from "react";
import { connect, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import {
  formatISO9075,
  parseISO,
  subDays,
  startOfDay,
  endOfDay,
} from "date-fns";
import { Prompt } from "react-router-dom";

import * as TYPES from "../../../constants/actionTypes";
import { Table } from "../../../components/Common/Table";
import AnomalyChart from "./AnomalyChart";
import {
  PressureAnomaly,
  PressureAnomalyType,
} from "../../../interfaces/types";
import {
  PrimaryButtonLarge,
  ConfirmButtonLarge,
  CancelButtonLarge,
} from "../../../components/Common/Button";
import { Modal } from "../../../components/Common/Modal";
import { DateTimePicker } from "../../../components/DateTimePicker";
import { AnomalyTypesModal } from "./AnomalyTypesModal";

// TODO: This component is getting a bit hefty, split the filter into a separate component?

enum ANOMALY_FLAGS {
  UNLABELED = "Unlabeled",
  FALSE = "No",
  TRUE = "Yes",
}

const AnomaliesPage = styled.div`
  display: flex;
  flex-direction: column;
`;
const AnomaliesHeader = styled.div``;
const AnomaliesActions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;
const AnomaliesFilter = styled.div`
  padding: 10px 20px 20px;
  background: #ececec;
`;
const AnomaliesFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const AnomaliesFilterHeader = styled.div`
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const AnomaliesUnlabeledFilterCheckbox = styled.input``;
const AnomaliesFilterLabel = styled.span`
  margin: 0 5px;
`;
const AnomaliesTableContainer = styled.div`
  > * {
    margin: 1rem;
  }
`;
const AnomaliesChartContainer = styled.div`
  width: 100%;
  height: 400px;
`;
const AnomalyFlagSelect = styled.select``;
const AnomalyTypeSelect = styled.select`
  max-width: 100%;
`;
const ModifiedCheckmark = styled.span`
  font-size: 1.3rem;
  margin-right: 8px;
  color: green;
`;

const ConfirmActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const ConfirmActionButtons = styled.div`
  display: flex;

  > button {
    margin-left: 10px;
  }
`;
const AnomalyTableStyles = css`
  .resizer {
    right: 0;
    width: 10px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    touch-action: none;
  }
`;
const AnomaliesNoData = styled.div`
  background: #ececec;
  padding: 20px;
`;
const DateTimeContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 2px;
`;

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateAnomalies: (token: string, data: any) => {
      dispatch({
        type: TYPES.UPDATE_ANOMALIES,
        payload: {
          token,
          data,
        },
      });
    },
    fetchAnomalies: (token: string, filter: PressureAnomalyFilter) => {
      dispatch({
        type: TYPES.FETCH_ANOMALIES,
        payload: {
          filter,
          token,
        },
      });
    },
    fetchAnomalyTypes: (token: string) => {
      dispatch({
        type: TYPES.FETCH_ANOMALY_TYPES,
        payload: {
          token,
        },
      });
    },
  };
};

const mapStateToProps = (state: any) => {
  return {
    initialAnomalies: state.admin.anomalies.data,
    anomalyTypes: state.admin.anomalies.types,
    isLoaded: state.admin.isLoaded,
    token: state.token.key,
  };
};

interface PressureAnomalyFilter {
  since?: Date;
  until?: Date;
  unlabeled?: boolean;
}

interface PressureAnomaliesProps {
  initialAnomalies: PressureAnomaly[];
  updateAnomalies(token: string, data: any): void;
  fetchAnomalies(token: string, filter: PressureAnomalyFilter): void;
  fetchAnomalyTypes(token: string): void;
  anomalyTypes: PressureAnomalyType[];
  isLoaded: boolean;
  token: any;
}
const PressureAnomaliesConnected = (props: PressureAnomaliesProps) => {
  const token = useSelector((state: any) => state.token.key);
  const preventTableStateReset = useRef(true);

  const {
    fetchAnomalies,
    updateAnomalies,
    fetchAnomalyTypes,
    initialAnomalies,
    anomalyTypes,
  } = props;

  const [anomalies, setAnomalies] = useState<PressureAnomaly[]>([]);

  // Anomalies to display on the Chart
  const [selectedAnomalies, setSelectedAnomalies] = useState<PressureAnomaly[]>(
    []
  );

  const [confirmMode, setConfirmMode] = useState<boolean>(false);

  const [showAnomalyTypesModal, setShowAnomalyTypesModal] = useState<boolean>(
    false
  );

  const [displayedAnomalies, setDisplayedAnomalies] = useState<
    PressureAnomaly[]
  >([]);

  useEffect(() => {
    // Don't reset the table state after these props change
    preventTableStateReset.current = true;
  }, [displayedAnomalies, anomalies]);

  const [filter, setFilter] = useState<PressureAnomalyFilter>({
    since: subDays(new Date(), 30), // Default past 30 days
    until: new Date(),
    unlabeled: true,
  });
  const [refetch, setRefetch] = useState<boolean>(true);

  useEffect(() => {
    fetchAnomalyTypes(token);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (refetch) {
      fetchAnomalies(token, filter);
      setRefetch(false);
    }
    // eslint-disable-next-line
  }, [refetch]);

  useEffect(() => {
    setAnomalies(initialAnomalies.map((a) => ({ ...a, modified: false })));
  }, [initialAnomalies]);

  const updateFilter = (newFilter: PressureAnomalyFilter) => {
    const updatedFilter = { ...filter, ...newFilter };
    setFilter(updatedFilter);
  };

  const handleModifyAnomaly = (
    anomaly: PressureAnomaly,
    update: { isAnomaly?: ANOMALY_FLAGS; anomalyType?: number }
  ) => {
    preventTableStateReset.current = true;
    let isAnomalyBoolean;
    if (update.isAnomaly === undefined) {
      isAnomalyBoolean = anomaly.isAnomaly;
    } else {
      isAnomalyBoolean = update.isAnomaly === ANOMALY_FLAGS.TRUE;
    }
    const updatedAnomaly = {
      ...anomaly,
      isAnomaly: isAnomalyBoolean,
      anomalyType:
        update.anomalyType !== undefined
          ? update.anomalyType
          : anomaly.anomalyType,
      modified: true,
    };

    const updatedAnomalies = anomalies.map((a) =>
      a.id === anomaly.id ? updatedAnomaly : a
    );
    setAnomalies(updatedAnomalies);
  };

  const getColumns = useMemo(
    () => [
      {
        Header: "Anomaly ID",
        id: "id",
        accessor: "id",
        minWidth: 60,
        Cell: ({ row }: any) => {
          // The built-in selection logic is wonky in this use case, so we'll provide our own...
          const isSelected = selectedAnomalies.includes(row.original);
          return (
            <>
              <input
                onChange={() => {
                  if (isSelected) {
                    setSelectedAnomalies(
                      selectedAnomalies.filter((a) => a.id !== row.original.id)
                    );
                  } else {
                    setSelectedAnomalies([
                      ...selectedAnomalies,
                      row.original as PressureAnomaly,
                    ]);
                  }
                }}
                type="checkbox"
                checked={isSelected}
                id={`select-${row.original.id}`}
              />
              <label htmlFor={`select-${row.original.id}`}>
                {row.original.id}
              </label>
            </>
          );
        },
      },
      {
        Header: "Pressure Curve ID",
        id: "pressureDataId",
        accessor: "pressureDataId",
        minWidth: 60,
      },
      {
        Header: "Device serial number",
        id: "deviceSerialNumber",
        accessor: "deviceSerialNumber",
        minWidth: 60,
      },
      {
        Header: "Created at",
        accessor: ({ createdAt }: any) => formatISO9075(parseISO(createdAt)),
        disableFilters: true,
        minWidth: 80,
      },
      {
        Header: "Waste fraction",
        id: "wasteFraction",
        accessor: "wasteFraction",
        minWidth: 80,
      },
      {
        Header: "Device owner",
        id: "deviceOwner",
        accessor: "deviceOwner",
        minWidth: 60,
      },
      {
        Header: "Score",
        accessor: "anomalyScore",
        disableFilters: true,
        Cell: (row: any) => <strong>{row.value.toFixed(3)}</strong>,
        minWidth: 40,
      },
      {
        Header: "Anomaly type",
        accessor: "anomalyType",
        disableFilters: true,
        minWidth: 60,
        Cell: ({ row }: any) => {
          row.isSelected = !!selectedAnomalies.find((a) => a === row.original);
          if (confirmMode) {
            return (
              <span>
                {
                  anomalyTypes.find((t) => t.id === row.values.anomalyType)
                    ?.name
                }
              </span>
            );
          } else {
            return (
              <>
                <AnomalyTypeSelect
                  name="anomalyType"
                  id="anomalyType"
                  value={row.values.anomalyType}
                  onChange={(e) =>
                    handleModifyAnomaly(row.original, {
                      anomalyType: parseInt(e.target.value),
                    })
                  }
                >
                  <option value={"0"}>None</option>
                  {anomalyTypes.map((type: any) => {
                    if (type.id === 0) return null; // ID 0 shouldn't be possible, but filter it out anyway just in case.
                    return <option value={type.id}>{type.name}</option>;
                  })}
                </AnomalyTypeSelect>
              </>
            );
          }
        },
      },
      {
        Header: "Is anomaly",
        accessor: "isAnomaly",
        disableFilters: true,
        Cell: ({ row }: any) => {
          if (confirmMode) {
            return row.original.isAnomaly ? "Yes" : "No";
          } else {
            // isAnomaly is saved to the DB as true, false, or null, which makes typing a little wonky.
            let selectedValue;
            if (row.values.isAnomaly === null) {
              selectedValue = ANOMALY_FLAGS.UNLABELED;
            } else {
              selectedValue = row.values.isAnomaly
                ? ANOMALY_FLAGS.TRUE
                : ANOMALY_FLAGS.FALSE;
            }
            return (
              <AnomalyFlagSelect
                name="isAnomaly"
                id="isAnomaly"
                onChange={(e) =>
                  handleModifyAnomaly(row.original, {
                    isAnomaly: e.target.value as ANOMALY_FLAGS,
                  })
                }
                value={selectedValue}
              >
                <option disabled={true} value={ANOMALY_FLAGS.UNLABELED}>
                  Not rated
                </option>
                <option value={ANOMALY_FLAGS.TRUE}>Yes</option>
                <option value={ANOMALY_FLAGS.FALSE}>No</option>
              </AnomalyFlagSelect>
            );
          }
        },
      },
      {
        Header: "",
        id: "modified",
        Cell: ({ row }: any) => {
          return (
            <span>
              {row.original.modified}
              {row.original.modified && (
                <ModifiedCheckmark>✓</ModifiedCheckmark>
              )}
            </span>
          );
        },
        width: 40,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmMode, selectedAnomalies, anomalies]
  );

  useEffect(() => {}, []);

  useEffect(() => {
    if (confirmMode) {
      setDisplayedAnomalies(anomalies.filter((a) => a.modified));
    } else {
      setDisplayedAnomalies(anomalies);
    }
  }, [anomalies, confirmMode]);

  const handleConfirmMode = (newState: boolean) => {
    setConfirmMode(newState);
    setSelectedAnomalies([]);
  };

  const handleUpdateAnomalies = () => {
    updateAnomalies(
      token,
      anomalies.filter((a) => a.modified)
    );
    fetchAnomalies(token, filter); // Fetch a new batch of anomalies
  };

  const nModifiedAnomalies = anomalies.filter((a) => a.modified).length;

  return (
    <>
      <AnomaliesPage>
        <Prompt
          when={anomalies.some((a) => a.modified)}
          message="There are unsaved anomalies, do you want to discard the changes?"
        />
        <>
          <AnomaliesHeader>
            <AnomaliesFilter>
              <AnomaliesFilterHeader>
                Filters
                <PrimaryButtonLarge onClick={() => setRefetch(true)}>
                  Apply
                </PrimaryButtonLarge>
              </AnomaliesFilterHeader>
              <AnomaliesFilterContainer>
                <div>
                  <AnomaliesFilterLabel>
                    Show only labeled anomalies:
                  </AnomaliesFilterLabel>
                  <AnomaliesUnlabeledFilterCheckbox
                    type="checkbox"
                    onChange={(e) => {
                      updateFilter({ unlabeled: !e.target.checked });
                    }}
                    checked={!filter.unlabeled}
                  />
                </div>
                <DateTimeContainer>
                  <AnomaliesFilterLabel>
                    Show anomalies created between:
                  </AnomaliesFilterLabel>
                  <DateTimePicker
                    eventLog={true}
                    selectedDate={filter.since}
                    handleChange={(v: Date) => {
                      // TODO: Timezones might mess this up?
                      updateFilter({ since: startOfDay(v) });
                    }}
                    name={"since"}
                  />
                  <div
                    style={{
                      width: "32px",
                      textAlign: "center",
                    }}
                  >
                    -
                  </div>
                  <DateTimePicker
                    eventLog={true}
                    selectedDate={filter.until}
                    handleChange={(v: Date) => {
                      updateFilter({ until: endOfDay(v) });
                    }}
                    name={"until"}
                  />
                </DateTimeContainer>
              </AnomaliesFilterContainer>
            </AnomaliesFilter>
            <AnomaliesActions>
              {confirmMode ? (
                <ConfirmActions>
                  <div>
                    The following <b>{nModifiedAnomalies}</b> anomalies will be
                    rated. Click CONFIRM to save these changes to the database.{" "}
                    <br />
                  </div>
                  <ConfirmActionButtons>
                    <CancelButtonLarge onClick={() => handleConfirmMode(false)}>
                      Cancel
                    </CancelButtonLarge>
                    <ConfirmButtonLarge
                      onClick={() => {
                        handleUpdateAnomalies();
                        handleConfirmMode(false); // Return to original view, but do not reset the state
                      }}
                    >
                      Confirm
                    </ConfirmButtonLarge>
                  </ConfirmActionButtons>
                </ConfirmActions>
              ) : (
                <>
                  <div>
                    <span>
                      Found {anomalies?.length}
                      {anomalies?.length >= 200 && "+"} anomalies
                    </span>{" "}
                    <span>
                      (<b>{nModifiedAnomalies}</b> of <b>{anomalies?.length}</b>{" "}
                      rated)
                    </span>
                  </div>
                  <ConfirmActionButtons>
                    <ConfirmButtonLarge
                      onClick={() => setShowAnomalyTypesModal(true)}
                    >
                      Anomaly Types...
                    </ConfirmButtonLarge>
                    <PrimaryButtonLarge
                      onClick={() => handleConfirmMode(true)}
                      disabled={nModifiedAnomalies === 0}
                    >
                      Save
                    </PrimaryButtonLarge>
                  </ConfirmActionButtons>
                </>
              )}
            </AnomaliesActions>
          </AnomaliesHeader>
          {!props.isLoaded ? (
            <span>Loading...</span>
          ) : (
            <>
              <AnomaliesTableContainer>
                {anomalies?.length === 0 ? (
                  <AnomaliesNoData>
                    No anomalies to show for the given filter conditions
                  </AnomaliesNoData>
                ) : (
                  <Table
                    data={displayedAnomalies}
                    columns={getColumns}
                    enableFilters={true}
                    resizeable={true}
                    initialState={{
                      sortBy: [{ id: "anomalyScore", desc: true }],
                      // page: page
                    }}
                    sortable={true}
                    tableStyles={AnomalyTableStyles}
                    preventStateReset={preventTableStateReset}
                    paginate={true}
                    pageSize={10}
                  />
                )}
              </AnomaliesTableContainer>
              <AnomaliesChartContainer>
                <AnomalyChart anomalies={selectedAnomalies} />
              </AnomaliesChartContainer>
            </>
          )}
        </>
        <Modal
          isOpen={showAnomalyTypesModal}
          handleClose={() => setShowAnomalyTypesModal(false)}
          content={<AnomalyTypesModal />}
          title="Anomaly types"
          contentPadding={0}
          height="600"
        />
      </AnomaliesPage>
    </>
  );
};

export const PressureAnomalies = connect(
  mapStateToProps,
  mapDispatchToProps
)(PressureAnomaliesConnected);
