import * as TYPES from "../constants/actionTypes";
import { DeviceRoute, DeviceType } from "../interfaces/types";
import { mapOptions } from "../constants/options";
import { convertToDeviceType } from "../utils/utils";
import { GroupHierarchyApi } from "../../../server/src/shared/apiTypes";

const initialState: any = {
  zoom: mapOptions.defaultZoom,
  bounds: undefined,
  center: mapOptions.defaultCenter,
  visibleRoutes: [],
  loadedDeviceRoutes: [],
};

const map = (state = initialState, action: any) => {
  switch (action.type) {
    case TYPES.MAP_PARAMETERS_UPDATED: {
      const payload = action.payload;
      return { ...state, ...payload };
    }
    case TYPES.FETCH_GROUP_HIERARCHY_SUCCESS: {
      /**
       * These values are returned from the backend, but they don't actually exist for any groups in
       * the database. The frontend code never updates these values, and they are not created
       * automatically. For this reason, they don't have an effect on the map's options.
       *
       * Also, now that users can belong to multiple groups, it is not clear from which of the
       * user's groups these settings should be taken from. For this reason, these settings might
       * work better as user-level settings instead, if they are taken to use.
       */
      const groups = (action.payload as GroupHierarchyApi).hierarchies;
      let zoom = groups[0].options.zoom;
      if (!zoom) {
        zoom = state.zoom;
      }
      let sw = groups[0].options.sw;
      let se = groups[0].options.se;
      let ne = groups[0].options.ne;
      let nw = groups[0].options.nw;
      let bounds: any = { ne: {}, nw: {}, sw: {}, se: {} };
      if (state.bounds) {
        bounds = state.bounds;
      }
      if (sw.lat && ne.lat) {
        bounds.ne.lat = ne.lat;
        bounds.ne.lng = ne.lng;
        bounds.nw.lat = nw.lat;
        bounds.nw.lng = nw.lng;
        bounds.sw.lat = sw.lat;
        bounds.sw.lng = sw.lng;
        bounds.se.lat = se.lat;
        bounds.se.lng = se.lng;
      }
      const optionsCenter = groups[0].options.center;
      let center = state.center;
      if (optionsCenter.lat && optionsCenter.lng) {
        center[0] = optionsCenter.lat;
        center[1] = optionsCenter.lng;
      }
      return { ...state, zoom: zoom, bounds: bounds, center: center };
    }
    case TYPES.FETCH_DEVICES_ROUTES_SUCCESS: {
      const { routes } = action.payload;
      const loadedDeviceRoutes: Array<DeviceRoute> = [];
      for (const route of routes) {
        const device = convertToDeviceType([route.device])[0];
        loadedDeviceRoutes.push({
          device: device,
          route: route.route.slice(),
        });
      }
      return { ...state, loadedDeviceRoutes };
    }
    case TYPES.FETCH_DEVICES_ROUTES_FAILURE: {
      // TODO
      return state;
    }
    case TYPES.SHOW_DEVICE_ROUTE: {
      const device: DeviceType = action.payload.device;
      const visible: boolean = action.payload.visible;
      let routes: Array<DeviceRoute> = state.visibleRoutes.filter(
        (route: DeviceRoute): boolean => {
          if (route.device.serialNumber !== device.serialNumber) {
            return true;
          }
          return false;
        }
      );
      if (visible) {
        // Find route
        let deviceRoute: DeviceRoute | undefined = undefined;
        for (const route of state.loadedDeviceRoutes) {
          if (route.device.serialNumber === device.serialNumber) {
            deviceRoute = route;
          }
        }
        if (deviceRoute) {
          routes.push(deviceRoute);
        }
      }
      return {
        ...state,
        visibleRoutes: routes,
      };
    }
    default: {
      return state;
    }
  }
};
export default map;
