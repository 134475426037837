import { GoogleMarker } from "./GoogleMarker";
import { v4 as uuidv4 } from "uuid";
import { GoogleClusterMarker } from "./GoogleClusterMarker";
import { Polyline } from "./Polyline";
import {
  latLngToPoint,
  pointToLatLng,
  getPointByAngleAndDistance,
  translatePoint,
} from "../utils/mapUtils";

const circleRadius = 120;
const clusterRadius = 26;

export function getPointGroupMarker(props: any) {
  const angle = 360 / props.points.length;

  // Center of the cluster
  const clusterCenterPoint = latLngToPoint(
    new props.maps.LatLng(props.lat, props.lng),
    props.map
  );

  // Device point based on index, depends on circleRadius
  const getDevicePoint = (index: number) => {
    return translatePoint(
      getPointByAngleAndDistance(angle * (index + 1), circleRadius),
      clusterCenterPoint.x,
      clusterCenterPoint.y
    );
  };

  // Returns path for polyline (start and end points)
  const getMarkersForPolyline = (index: number) => {
    const clusterPoint = getPointByAngleAndDistance(
      angle * (index + 1),
      clusterRadius
    );
    // Starting point of the polyline
    const clusterEdgePoint = translatePoint(
      clusterPoint,
      clusterCenterPoint.x,
      clusterCenterPoint.y
    );

    // Ending point of the polyline
    const devicePoint = getDevicePoint(index);

    const clusterLatLng = pointToLatLng(clusterEdgePoint, props.map);
    const deviceLatLng = pointToLatLng(devicePoint, props.map);
    return [clusterLatLng, deviceLatLng];
  };

  // Returns position for device
  const getDevicePosition = (index: number) => {
    // Move point so its center is where polyline ends
    const deviceCirclePoint = getDevicePoint(index);

    return pointToLatLng(deviceCirclePoint, props.map);
  };

  const getMarkers = () => {
    return props.points.map((point: any, index: number) => {
      const marker = getDevicePosition(index);
      return (
        <GoogleMarker
          {...point}
          lat={marker.lat()}
          lng={marker.lng()}
          key={uuidv4()}
          onShowAnalytics={props.onShowAnalytics}
        />
      );
    });
  };

  const getPolylines = () => {
    return props.points.map((_point: any, index: number) => {
      return (
        <Polyline
          markers={getMarkersForPolyline(index)}
          map={props.map}
          maps={props.maps}
          key={uuidv4()}
        />
      );
    });
  };

  return [
    <GoogleClusterMarker
      key={uuidv4()}
      id={props.id}
      devices={props.points.length}
      lat={props.lat}
      lng={props.lng}
      active={false}
      onClicked={() => {}}
      zoom={props.zoom}
      warning={props.warning}
      pointGroup={true}
    />,
    getMarkers(),
    getPolylines(),
  ];
}
