import { Component } from "react";
import { Redirect } from "react-router";
import { connect } from "react-redux";

import { isTokenExpired } from "../utils/utils";
import * as TYPES from "../constants/actionTypes";
import { history } from "../store/configureStore";
import i18next from "i18next";
import { groupHierarchiesSelector } from "../selectors/groups";
import { TreeNode } from "../reducers/groups";

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchGroups: (token: string) => {
      dispatch({
        type: TYPES.FETCH_GROUP_HIERARCHY,
        payload: { token: token },
      });
    },
    fetchUser: (token: string, userName: string | undefined) => {
      dispatch({
        type: TYPES.FETCH_USER,
        payload: { token: token, userName: userName },
      });
    },
    logout: (token: string, userName: string) => {
      dispatch({
        type: TYPES.LOGOUT,
        payload: { user: userName, token: token },
      });
    },
  };
};

const mapStateToProps = (state: any) => {
  return {
    token: state.token,
    user: state.users,
    groupHierarchies: groupHierarchiesSelector(state),
  };
};
interface PageProps {
  fetchUser(token: string, userName: string | undefined): void;
  fetchGroups(token: string): void;
  logout(token: string, userName: string): void;
  token: any;
  user: any;
  groupHierarchies: TreeNode[];
  children: JSX.Element[] | JSX.Element;
}
/**
 * This is a wrapper container class which can be use as base page
 * for building pages on SMART frontend. Idea is that this class
 * does automatically redux state fill from backend so that we do not
 * need to copy redux state fill code everywhere.
 *
 * @class PageConnected
 * @extends {Component<PageProps, {}>}
 */
class PageConnected extends Component<PageProps, {}> {
  public componentDidMount(): void {
    if (this.props.token.key !== "") {
      if (this.props.groupHierarchies.length === 0) {
        this.props.fetchUser(this.props.token.key, undefined);
        this.props.fetchGroups(this.props.token.key);

        if (window.gtag) {
          window.gtag("event", "user_groups", {
            groups: this.props.user.groups
              .map((group: any) => group.name)
              .join(", "),
          });
        }
      }
    }
  }
  public componentDidUpdate(prevProps: any) {
    if (prevProps.user.language !== this.props.user.language) {
      i18next.changeLanguage(this.props.user.language);
    }
  }

  public render(): JSX.Element | null {
    if (isTokenExpired(this.props.token)) {
      if (this.props.token !== "" && this.props.user.userName !== "") {
        this.props.logout(this.props.token.key, this.props.user.userName);
      }
      return this.redirectToRoot();
    }

    // Redirect to /devices page if a logged-in user tries to access admin pages
    if (
      history.location.pathname.startsWith("/admin") &&
      !this.props.user.admin
    ) {
      return <Redirect to={{ pathname: "/devices" }} />;
    }

    return <>{this.props.children}</>;
  }

  private redirectToRoot(): JSX.Element {
    return (
      <Redirect
        to={{
          pathname: "/",
          /**
           * Save current location to `referrer` so that LoginPage can navigate to the location that
           * redirected the user to the login. For example:
           *
           * 1. An unauthenticated user navigates to /admin/users
           * 2. This component redirects the user to "/" to login
           * 3. The login component redirects the user to /admin/users using the `referrer` field
           *    set below
           */
          state: { referrer: history.location },
        }}
      />
    );
  }
}

export const Page = connect(mapStateToProps, mapDispatchToProps)(PageConnected);
