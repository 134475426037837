import { useEffect } from "react";
import * as TYPES from "../../../constants/actionTypes";

import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import { FormInput } from "../../../components/Common/FormInput";
import { FormRectangle } from "../../../components/Common/FormRectangle";
import { FormBackground } from "../../../components/Common/FormBackground";
import { FormSubmitButton } from "../../../components/Common/FormSubmitButton";
import { FlexBoxWrapper } from "../../../components/Common";
import { FormTitle } from "../../../components/Common/FormTitle";
import HGDeviceSchema from "./HGDeviceSchema";
import { FormWrapper } from "../../../components/Common/FormWrapper";
import {
  adminHGDevicesSelector,
  adminSubmittingSelector,
  isHGDevicesLoadingSelector,
} from "../../../selectors/admin";
import { GroupSelect } from "../GroupSelect";
import { groupHierarchiesSelector } from "../../../selectors/groups";
import { FillLevel } from "@shared/types";
import { getTimeZone } from "@shared/time";
import { EmailAlertEditor } from "./DeviceEditElements";

type FormValues = {
  hgId: string;
  serialNumber: string;
  groupId: number;
  wasteFraction: string;
  productType: string;
  site: string;
  city: string;
  address: string;
  fillLevel: string;
  weekdays: number[];
  startHour: number;
  endHour: number;
  resendHours: number | null | "";
  timeZone: string;
  emptyingMsg: boolean;
  recipients: string[];
};

type HGDeviceFormProps = {
  values: FormValues;
  onSubmit: (values: FormValues) => void;
  isSubmitting: boolean;
  title: string;
  nameDisabled?: boolean;
};

function HGDeviceForm(props: HGDeviceFormProps) {
  const groupHierarchy = useSelector(groupHierarchiesSelector);
  return (
    <FormBackground cursor={props.isSubmitting ? "wait" : undefined}>
      <FormRectangle>
        <FormTitle title={props.title} />
        <Formik<FormValues>
          initialValues={{
            ...props.values,
          }}
          onSubmit={(values, _actions) => {
            if (props.isSubmitting) {
              return;
            }

            props.onSubmit({
              hgId: values.hgId,
              groupId: values.groupId,
              serialNumber: values.serialNumber.trim(),
              wasteFraction: values.wasteFraction.trim(),
              productType: values.productType.trim(),
              site: values.site.trim(),
              city: values.city.trim(),
              address: values.address.trim(),
              fillLevel: values.fillLevel,
              weekdays: values.weekdays,
              startHour: values.startHour,
              endHour: values.endHour,
              resendHours:
                values.resendHours === "" ? null : values.resendHours,
              timeZone: values.timeZone,
              emptyingMsg: values.emptyingMsg,
              recipients: values.recipients,
            });
          }}
          validationSchema={HGDeviceSchema}
        >
          {(formikProps) => {
            const {
              errors,
              values,
              touched,
              handleChange,
              setFieldValue,
              handleBlur,
            } = formikProps;

            return (
              <FormWrapper>
                <FormInput
                  id="hgId"
                  type="number"
                  label="H&G id"
                  error={touched.hgId && errors.hgId ? errors.hgId : undefined}
                  value={values.hgId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={props.nameDisabled}
                />
                <GroupSelect
                  id="groupId"
                  label="Owner group"
                  value={values.groupId}
                  error={errors.groupId}
                  options={groupHierarchy}
                  onChange={setFieldValue}
                  required={true}
                />
                <FormInput
                  id="serialNumber"
                  type="string"
                  label="serialNumber"
                  error={
                    touched.serialNumber && errors.serialNumber
                      ? errors.serialNumber
                      : undefined
                  }
                  value={values.serialNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={true}
                />
                <FormInput
                  id="wasteFraction"
                  type="string"
                  label="Waste Fraction"
                  error={
                    touched.wasteFraction && errors.wasteFraction
                      ? errors.wasteFraction
                      : undefined
                  }
                  value={values.wasteFraction}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormInput
                  id="productType"
                  type="string"
                  label="Product Type"
                  error={
                    touched.productType && errors.productType
                      ? errors.productType
                      : undefined
                  }
                  value={values.productType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormInput
                  id="site"
                  type="string"
                  label="Site"
                  error={touched.site && errors.site ? errors.site : undefined}
                  value={values.site}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormInput
                  id="city"
                  type="string"
                  label="City"
                  error={touched.city && errors.city ? errors.city : undefined}
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormInput
                  id="address"
                  type="string"
                  label="Address"
                  error={
                    touched.address && errors.address
                      ? errors.address
                      : undefined
                  }
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <EmailAlertEditor {...formikProps} />
                <FlexBoxWrapper marginTop={20}>
                  <Link to={`/admin/devices/device-management`}>
                    <FormSubmitButton
                      disabled={props.isSubmitting}
                      cursor={props.isSubmitting ? "wait" : undefined}
                    >
                      Cancel
                    </FormSubmitButton>
                  </Link>
                  &nbsp;
                  <FormSubmitButton
                    type="submit"
                    disabled={props.isSubmitting}
                    cursor={props.isSubmitting ? "wait" : undefined}
                  >
                    Submit
                  </FormSubmitButton>
                </FlexBoxWrapper>
              </FormWrapper>
            );
          }}
        </Formik>
      </FormRectangle>
    </FormBackground>
  );
}

export function EditHGDeviceForm(props: RouteComponentProps<{ id: string }>) {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.token.key);
  const isLoading = useSelector(isHGDevicesLoadingSelector);
  const isSubmitting = useSelector(adminSubmittingSelector);

  const devices = useSelector(adminHGDevicesSelector);

  useEffect(() => {
    dispatch({
      type: TYPES.GET_ALL_HG_DEVICES,
      payload: { token },
    });
  }, [dispatch, token]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const device = devices.find(
    (device: { hgId: string }) => device.hgId === props.match.params.id
  );

  if (!device?.hgId) {
    return <div>Could not find device</div>;
  }

  return (
    <HGDeviceForm
      title="Edit H&G device"
      values={{
        hgId: device?.hgId,
        groupId: device?.groupId,
        serialNumber: device?.serialNumber || "",
        wasteFraction: device?.wasteFraction || "",
        productType: device?.productType || "",
        site: device?.site || "",
        city: device?.city || "",
        address: device?.address || "",
        fillLevel: device?.fillLevel || FillLevel.EMPTY.toString(),
        startHour: device?.startHour ?? 0,
        endHour: device?.endHour ?? 24,
        resendHours: device?.resendHours ?? null,
        timeZone: device?.timeZone || getTimeZone(),
        weekdays: device?.weekdays || [],
        emptyingMsg: device?.emptyingMsg || false,
        recipients: device?.recipients || [],
      }}
      onSubmit={(values) => {
        dispatch({
          type: TYPES.PATCH_HG_DEVICE,
          payload: { hgId: device?.hgId, data: values, token },
        });
      }}
      isSubmitting={isSubmitting}
      nameDisabled
    />
  );
}
