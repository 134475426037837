import { AxiosError } from "axios";
import { MutationCache, QueryCache, QueryClient } from "react-query";
import { toast } from "react-toastify";
import { ApiError, apiError } from "@shared/apiError";
import i18next from "i18next";

const defaultErrorToastId = "default-error-toast";

// TODO: Why doesn't this use the regular Notify component?
export const queryClient = new QueryClient({
  /**
   * Define global error handlers for both queries and mutations.
   */
  queryCache: new QueryCache({
    onError: (_error, query) => {
      /**
       * Only show error toasts if we already have data in the cache which indicates a failed
       * background update. Errors for initial loads (no data in the cache) should be handled
       * locally in the component using the query.
       */
      if (
        query.state.data !== undefined &&
        !toast.isActive(defaultErrorToastId)
      ) {
        toast.error(`An error occurred`, {
          autoClose: false,
          toastId: defaultErrorToastId,
        });
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, _query) => {
      /**
       * Handle all mutation errors here.
       */
      const err = error as AxiosError;
      const errorCode: ApiError | undefined = err?.response?.data?.error;

      if (errorCode && apiError.includes(errorCode)) {
        toast.error(i18next.t(`apiError.${errorCode}`), {
          autoClose: false,
          closeOnClick: false,
        });
      } else {
        if (!toast.isActive(defaultErrorToastId)) {
          toast.error("An error occurred", {
            autoClose: false,
            closeOnClick: false,
            toastId: defaultErrorToastId,
          });
        }
      }
    },
  }),
});
