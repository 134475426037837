import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import { Formik, FormikProps } from "formik";

import {
  adminIProtoxiDevicesSelector,
  adminSubmittingSelector,
  isIProtoxiDevicesLoadingSelector,
} from "../../../selectors/admin";
import * as TYPES from "../../../constants/actionTypes";
import { FormBackground } from "../../../components/Common/FormBackground";
import { FormRectangle } from "../../../components/Common/FormRectangle";
import { FormTitle } from "../../../components/Common/FormTitle";
import { FormWrapper } from "../../../components/Common/FormWrapper";

import { IProtoxiDeviceSchema } from "./IProtoxiDeviceSchema";
import { GroupSelect } from "../GroupSelect";
import { groupHierarchiesSelector } from "../../../selectors/groups";
import { FormInput } from "../../../components/Common/FormInput";
import { FlexBoxWrapper } from "../../../components/Common";
import { FormSubmitButton } from "../../../components/Common/FormSubmitButton";
import { EmailAlertEditor } from "./DeviceEditElements";
import { getTimeZone } from "@shared/time";
import _ from "lodash";

type FormValues = {
  iProtoxiId: string;
  groupId: number;
  pairedDeviceId: string;
  maxDistance: number;
  minDistance: number;
  fillLevel?: string;
  weekdays?: number[];
  startHour?: number;
  endHour?: number;
  resendHours?: number | null | "";
  timeZone?: string;
  emptyingMsg?: boolean;
  recipients?: string[];
};

export function EditIProtoxiDeviceForm(
  props: RouteComponentProps<{ id: string }>
) {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.token.key);
  const isLoading = useSelector(isIProtoxiDevicesLoadingSelector);
  const isSubmitting = useSelector(adminSubmittingSelector);

  const devices = useSelector(adminIProtoxiDevicesSelector);

  useEffect(() => {
    dispatch({
      type: TYPES.GET_ALL_IPROTOXI_DEVICES,
      payload: { token },
    });
  }, [dispatch, token]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const device = devices.find(
    (device: { id: number }) => device.id === parseInt(props.match.params.id)
  );

  if (!device?.id) {
    return <div>Could not find device</div>;
  }

  function FormContent(formikProps: FormikProps<FormValues>) {
    const groupHierarchy = useSelector(groupHierarchiesSelector);

    const {
      errors,
      values,
      touched,
      handleChange,
      setFieldValue,
      handleBlur,
    } = formikProps;

    return (
      <FormWrapper>
        <FormInput
          type="string"
          id="iProtoxiId"
          label="iProtoxi qrcode"
          error={
            touched.iProtoxiId && errors.iProtoxiId
              ? errors.iProtoxiId
              : undefined
          }
          value={values.iProtoxiId}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={true}
        />
        <GroupSelect
          id="groupId"
          label="Owner group"
          value={values.groupId}
          error={errors.groupId}
          options={groupHierarchy}
          onChange={setFieldValue}
          required={true}
        />
        <FormInput
          id="pairedDeviceId"
          type="string"
          label="Paired to Europress device (serial number)"
          error={
            touched.pairedDeviceId && errors.pairedDeviceId
              ? errors.pairedDeviceId
              : undefined
          }
          value={values.pairedDeviceId || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          required={true}
        />
        <FormInput
          id="minDistance"
          type="number"
          label="Minimum distance (used for fill level calculations)"
          error={
            touched.minDistance && errors.minDistance
              ? errors.minDistance
              : undefined
          }
          value={values.minDistance || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          required={true}
        />
        <FormInput
          id="maxDistance"
          type="number"
          label="Maximum distance (used for fill level calculations)"
          error={
            touched.maxDistance && errors.maxDistance
              ? errors.maxDistance
              : undefined
          }
          value={values.maxDistance || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          required={true}
        />
        <EmailAlertEditor {...formikProps} />
        <FlexBoxWrapper marginTop={20}>
          <Link to={`/admin/devices/device-management`}>
            <FormSubmitButton
              disabled={isSubmitting}
              cursor={isSubmitting ? "wait" : undefined}
            >
              Cancel
            </FormSubmitButton>
          </Link>
          &nbsp;
          <FormSubmitButton
            type="submit"
            disabled={isSubmitting}
            cursor={isSubmitting ? "wait" : undefined}
          >
            Submit
          </FormSubmitButton>
        </FlexBoxWrapper>
      </FormWrapper>
    );
  }

  return (
    <FormBackground cursor={isSubmitting ? "wait" : undefined}>
      <FormRectangle>
        <FormTitle title="Edit iProtoxi device" />
        <Formik<FormValues>
          initialValues={{
            iProtoxiId: device.iprotoxi_qrcode,
            groupId: device.group_id,
            pairedDeviceId: device?.Device?.serialNumber,
            maxDistance: device.max_distance,
            minDistance: device.min_distance,
            ...device.email_config,
            weekdays: device.email_config.weekdays.map((day: number) =>
              (day % 7).toString()
            ),
          }}
          onSubmit={(value, _actions) => {
            if (isSubmitting) {
              return;
            }

            const emailConfigValid =
              !!value.recipients?.length &&
              !!value.weekdays?.length &&
              value.fillLevel &&
              value.startHour &&
              value.endHour &&
              value.startHour < value.endHour;

            const emailConfig = emailConfigValid
              ? {
                  timeZone: getTimeZone(),
                  emptyingMsg: !!value.emptyingMsg,
                  recipients: value.recipients,
                  fillLevel: parseInt(value.fillLevel!),
                  weekdays: _.uniq(
                    value
                      .weekdays!.map((day: any) => parseInt(day))
                      .map((day: number) => {
                        // The frontend has Monday as 1 and Sunday as 0
                        // Backend wants Monday as 1, but Sunday as 7
                        if (day > 0) return day;
                        return 7;
                      })
                  ),
                  startHour: value.startHour,
                  endHour: value.endHour,
                  resendHours: value.resendHours,
                }
              : null;

            dispatch({
              type: TYPES.PATCH_IPROTOXI_DEVICE,
              payload: {
                iProtoxiDeviceId: device.id,
                data: {
                  groupId: value.groupId,
                  pairedDeviceId: value.pairedDeviceId || null,
                  maxDistance: value.maxDistance || null,
                  minDistance: value.minDistance || null,
                  emailConfig,
                },
                token,
              },
            });
          }}
          validationSchema={IProtoxiDeviceSchema}
        >
          {FormContent}
        </Formik>
      </FormRectangle>
    </FormBackground>
  );
}
