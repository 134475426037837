import { call, put } from "redux-saga/effects";
import { getToken } from "../api/token";
import * as TYPES from "../constants/actionTypes";
import { Notify } from "../components/Common/Notify";
import { getNotificationByAction } from "../utils/utils";
/**
 * Fetch token action. IF fetch fails puts action FETCH_TOKEN_FAILURE. For success passes data
 * through FETCH_TOKEN_SUCCESS action.
 * @export
 * @param {*} action
 */
export function* fetchToken(action: any) {
  try {
    const result = yield call(getToken, action);
    if (result.data.mfaRequired) {
      yield put({ type: TYPES.MFA_REQUIRED, payload: result.data });
    } else {
      yield put({ type: TYPES.FETCH_TOKEN_SUCCESS, payload: result.data });
    }
  } catch (err) {
    if (err?.response?.status >= 500) {
      // The request failed completely
      Notify.error(getNotificationByAction(TYPES.COMMUNICATION_ERROR), {
        detail: err?.response?.data,
      });
    } else if (err?.response?.data?.error === "user.login.lockout") {
      // User was locked out, return to login page.
      yield put({ type: TYPES.FETCH_TOKEN_FAILURE });
      Notify.error(getNotificationByAction(TYPES.FETCH_TOKEN_FAILURE), {
        detail: err?.response?.data?.error,
      });
    } else {
      // Probably wrong password/user combo
      yield put({ type: TYPES.FETCH_TOKEN_FAILURE });
      Notify.error(getNotificationByAction(TYPES.FETCH_TOKEN_FAILURE), {
        detail: err?.response?.data?.error,
      });
    }
  }
}
