import { Provider } from "react-redux";
import App from "./App";
import { Router } from "react-router-dom";
import { history } from "../store/configureStore";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { queryClient } from "../queries/client";

const Root = ({ store }: any) => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <App />
      </Router>
      {/* react-query devtools are automatically only used when process.env.NODE_ENV === 'development' */}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Provider>
);

export default Root;
