import TagManager from "react-gtm-module";
import { put } from "redux-saga/effects";
import * as TYPES from "./constants/actionTypes";

export function* initGTM(action: any) {
  const inEuropressGroup = action.payload.user.groups.includes("Europress");

  // "PUT_GTM_ID_HERE" is a placeholder we use to place GTM id in index.html
  if (!inEuropressGroup && window.gtmId && window.gtmId !== "PUT_GTM_ID_HERE") {
    TagManager.initialize({
      gtmId: window.gtmId,
    });
    yield put({ type: TYPES.GTM_INITIALIZED });
  }
}
