import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as TYPES from "../constants/actionTypes";
import { connect, useSelector } from "react-redux";
import styled from "styled-components";
import { format } from "date-fns";

import {
  PrimaryButtonLarge,
  CancelButtonLarge,
} from "./../components/Common/Button";
import { FormSubmitButton } from "./Common/FormSubmitButton";
import { SpinnerSmall } from "./../components/Spinner";

const mapDispatchToProps = (dispatch: any) => {
  return {
    sendDeletionRequest: (token: string) => {
      dispatch({
        type: TYPES.REQUEST_USER_DELETION,
        payload: { token },
      });
    },
  };
};

const mapStateToProps = (state: any) => {
  return {
    token: state.token,
  };
};

interface Props {
  sendDeletionRequest(token: string): void;
  token: any; // TODO: Type
}

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto";
  color: black;
  height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 1%;
`;
const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
`;
const DeletionRequestedText = styled.p`
  color: #aa3333;
  font-weight: 700;
`;

function RequestUserDeletionComponent(props: Props) {
  const { t } = useTranslation();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const user = useSelector((state: any) => state.users);
  const { deletionRequested } = user;
  const { sendDeletionRequest, token } = props;

  return (
    <>
      {!showConfirmation ? (
        <>
          <Title>{t("userDeletion.title")}</Title>
          <p>{t("userDeletion.info")}</p>
          {deletionRequested ? (
            <DeletionRequestedText>
              {t("userDeletion.deletionRequested", {
                time: format(new Date(deletionRequested), "dd.MM.yyyy"),
              })}
            </DeletionRequestedText>
          ) : (
            <Buttons>
              {sending ? (
                <SpinnerSmall />
              ) : (
                <FormSubmitButton onClick={() => setShowConfirmation(true)}>
                  {t("userDeletion.button")}
                </FormSubmitButton>
              )}
            </Buttons>
          )}
        </>
      ) : (
        <>
          <p>{t("userDeletion.confirmText")}</p>
          <Buttons>
            <CancelButtonLarge
              onClick={() => {
                sendDeletionRequest(token.key);
                setShowConfirmation(false);
                setSending(true);
              }}
            >
              {t("userDeletion.button")}
            </CancelButtonLarge>
            <PrimaryButtonLarge onClick={() => setShowConfirmation(false)}>
              {t("userDeletion.cancel")}
            </PrimaryButtonLarge>
          </Buttons>
        </>
      )}
    </>
  );
}

export const RequestUserDeletion = connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestUserDeletionComponent);

export default RequestUserDeletion;
