import { createSelector } from "reselect";
import { clusterOptions } from "../constants/options";
import {
  DeviceStatus,
  DeviceType,
  FillState,
  MachineType,
  SelectableColumns,
} from "../interfaces/types";
import { DeviceState } from "../reducers/devices";
import { TreeNode } from "../reducers/groups";
import { Cluster } from "../utils/cluster";
import { getPrimaryStatus } from "../utils/deviceStatus";
import {
  columnSortable,
  compareDevicesBySortColumn,
  filterDevicesByString,
  filterDevicesByGroup,
  getNumericEnumValues,
} from "../utils/utils";
import { selectedGroupSelector } from "./groups";
import { isEuropressUserSelector } from "./users";

/**
 * Selectors for device state.
 *
 * The rationale with these selectors is to create a selector hierarchy that outputs data that is
 * derived from devices. This includes:
 * - filtered & sorted device list for UI
 * - filtered & sorted container (skip) list for UI
 * - cluster instances containing filtered & sorted devices and skips for the map
 *
 * These selectors are automatically recalculated when the state they depend on is updated. This
 * ensures that the derived data can never get out of sync with the actual device, filter and sort
 * data.
 */

/**
 * UTILITY FUNCTIONS FOR FILTER & SORT
 */

function sortDevices(
  devices: Array<DeviceType>,
  sortOrder: number,
  sortColumn: SelectableColumns,
  forEuropress: boolean
): Array<DeviceType> {
  let sortableDevices: Array<DeviceType> = [];
  let restDevices: Array<DeviceType> = [];
  for (const device of devices) {
    if (columnSortable(device, sortColumn, forEuropress)) {
      sortableDevices.push(device);
    } else {
      restDevices.push(device);
    }
  }
  sortableDevices.sort((left: DeviceType, right: DeviceType): number => {
    return compareDevicesBySortColumn(
      left,
      right,
      sortOrder,
      sortColumn,
      forEuropress
    );
  });
  return [...sortableDevices, ...restDevices];
}

function getFilteredDevices(
  devices: Array<DeviceType>,
  filters: {
    query: string;
    group: TreeNode | undefined;
    status: DeviceStatus[];
    fillLevel: FillState[];
    machineType: MachineType[];
  },
  machineType: MachineType = MachineType.Compactor
): Array<DeviceType> {
  let visibleDevices: Array<DeviceType> = devices;

  if (filters.group) {
    visibleDevices = filterDevicesByGroup(visibleDevices, filters.group);
  }

  if (machineType !== MachineType.Skip) {
    if (0 < filters.status.length) {
      visibleDevices = visibleDevices.filter((dev) =>
        filters.status.includes(getPrimaryStatus(dev))
      );
    }

    if (0 < filters.fillLevel.length) {
      visibleDevices = visibleDevices.filter((dev) => {
        const fillLevel =
          dev.fillLevel != null &&
          getNumericEnumValues(FillState).includes(dev.fillLevel)
            ? dev.fillLevel
            : FillState.Unknown;

        return filters.fillLevel.includes(fillLevel);
      });
    }

    if (0 < filters.machineType.length) {
      visibleDevices = visibleDevices.filter((dev) =>
        // Currently we are only interested in showing balers and compactors (everything that is not a
        // baler is considered to be a compator)
        filters.machineType.includes(
          dev.machineType === MachineType.Baler ||
            dev.machineType === MachineType.PullingBaler
            ? MachineType.Baler
            : MachineType.Compactor
        )
      );
    }
  }

  if (filters.query !== "") {
    visibleDevices = filterDevicesByString(visibleDevices, filters.query);
  }

  return visibleDevices;
}

/**
 * SELECTORS FOR ALL DEVICES
 */

const deviceStateSelector = (state: any): DeviceState => state.devices;

const rawDevicesSelector = createSelector(
  deviceStateSelector,
  (state) => state.elements
);

const sortSelector = createSelector(deviceStateSelector, (state) => {
  return {
    sortColumn: state.sortColumn,
    sortOrder: state.sortOrder,
  };
});

export const filterCountsSelector = createSelector(
  deviceStateSelector,
  (deviceState) => ({
    fillLevelCount: deviceState.fillLevelFilter.length,
    statusCount: deviceState.statusFilter.length,
    machineTypeCount: deviceState.machineTypeFilter.length,
  })
);

export const filterSelector = createSelector(
  deviceStateSelector,
  selectedGroupSelector,
  (deviceState, selectedGroup) => ({
    query: deviceState.query,
    group: selectedGroup ? selectedGroup : undefined,
    status: deviceState.statusFilter,
    fillLevel: deviceState.fillLevelFilter,
    machineType: deviceState.machineTypeFilter,
  })
);

const filteredDevicesSelector = createSelector(
  rawDevicesSelector,
  filterSelector,
  (devices, filters) =>
    getFilteredDevices(devices, filters, MachineType.Compactor)
);

export const visibleDevicesSelector = createSelector(
  filteredDevicesSelector,
  sortSelector,
  isEuropressUserSelector,
  (devices, sort, isEuropressUser) =>
    sortDevices(devices, sort.sortOrder, sort.sortColumn, isEuropressUser)
);

export const deviceClusterEngineSelector = createSelector(
  filteredDevicesSelector,
  (devices) => {
    const clusterEngine = new Cluster<DeviceType>(
      clusterOptions.defaultRadius,
      clusterOptions.defaultMinZoom,
      clusterOptions.defaultMaxZoom
    );
    clusterEngine.load(devices);

    return clusterEngine;
  }
);

/**
 * SELECTORS FOR CONTAINER (SKIP) DEVICES
 */

export const rawContainersSelector = createSelector(
  rawDevicesSelector,
  (devices) => devices.filter((dev) => dev.machineType === MachineType.Skip)
);

export const filteredContainersSelector = createSelector(
  rawContainersSelector,
  filterSelector,
  (containers, filters) =>
    getFilteredDevices(containers, filters, MachineType.Skip)
);

export const visibleContainersSelector = createSelector(
  filteredContainersSelector,
  sortSelector,
  isEuropressUserSelector,
  (containers, sort, isEuropressUser) =>
    sortDevices(containers, sort.sortOrder, sort.sortColumn, isEuropressUser)
);

export const containerClusterEngineSelector = createSelector(
  filteredContainersSelector,
  (containers) => {
    const clusterEngine = new Cluster<DeviceType>(
      clusterOptions.defaultRadius,
      clusterOptions.defaultMinZoom,
      clusterOptions.defaultMaxZoom
    );
    clusterEngine.load(containers);

    return clusterEngine;
  }
);
