import * as deviceApi from "../api/devices";
import { Notify } from "./Common/Notify";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { SpinnerSmall } from "./Spinner";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import imageIcon from "../assets/icon-image.svg";
import { SecondaryButtonSmall } from "./Common/Button";
import { getNotificationByAction } from "../utils/utils";

const Image = styled.img<{ height: string; width: string }>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  object-fit: contain;
`;

const PlaceholderImage = styled.img.attrs({ src: imageIcon })<{
  height: string;
  width: string;
}>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  object-fit: contain;
`;

const NoImagesText = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
`;

const NoImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Arrow = styled(SecondaryButtonSmall).attrs({ type: "button" })<{
  direction: "prev" | "next";
}>`
  margin: 0 10px;
  position: absolute;
  z-index: 2;
  top: calc(50% - 12.5px);
  width: 20px;
  height: 25px;
  padding: 0;
  cursor: pointer;

  ${(props) => (props.direction === "prev" ? "left: 0;" : "right: 0;")}
`;

const StyledCarousel = styled(Carousel)`
  // Remove margin from status string
  p.carousel-status {
    margin: 0;
  }
`;

type DeviceImage = {
  signedUrl: string;
  id: number;
};

export function DeviceImageCarousel({
  token,
  serialNumber,
  width,
  height,
}: {
  token: string;
  serialNumber: string;
  width: string;
  height: string;
}) {
  const [images, setImages] = useState<DeviceImage[]>();
  const { t } = useTranslation();

  const fetchImages = useCallback(async () => {
    try {
      const response = await deviceApi.getDeviceImages({
        payload: {
          token,
          serialNumber,
        },
      });

      if (Array.isArray(response.data.images)) {
        setImages(response.data.images);
      } else {
        setImages([]);
      }
    } catch (error) {
      Notify.error(getNotificationByAction("DEFAULT_ERROR"));
      setImages([]);
    }
  }, [token, serialNumber]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  if (!images) {
    return <SpinnerSmall />;
  } else if (images.length === 0) {
    return (
      <NoImagesContainer>
        <PlaceholderImage width={width} height={height} />
        <NoImagesText>
          {t("editContainerForm.noImages", "No images")}
        </NoImagesText>
      </NoImagesContainer>
    );
  }

  return (
    <StyledCarousel
      infiniteLoop
      showThumbs={false}
      showIndicators={false}
      statusFormatter={(currentItem, total) => `${currentItem} / ${total}`}
      renderArrowPrev={(onClick) =>
        1 < images.length ? (
          <Arrow onClick={onClick} direction="prev">
            &#x25C0;
          </Arrow>
        ) : null
      }
      renderArrowNext={(onClick) =>
        1 < images.length ? (
          <Arrow onClick={onClick} direction="next">
            &#x25BA;
          </Arrow>
        ) : null
      }
    >
      {images.map((image) => (
        <Image
          key={image.id}
          src={image.signedUrl}
          width={width}
          height={height}
        />
      ))}
    </StyledCarousel>
  );
}
