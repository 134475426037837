import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as TYPES from "../constants/actionTypes";
import { connect, useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { FormSubmitButton } from "./Common/FormSubmitButton";
import { Icon } from "./Common/Icon";
import icon_status_ok from "../assets/icon-status-ok.svg";
import icon_alert from "../assets/icon-alert.svg";

// TODO: This component could do with a bit of cleanup.

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto";
  color: black;
  height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 1%;
`;
const Content = styled.div``;
const Info = styled.div`
  font-size: 16px;
  font-family: "Roboto";
  color: black;
  padding: 20px 0;
`;
const MFAButton = styled(FormSubmitButton)`
  align-self: center;
  width: 0;
`;
const FlexCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const StatusIcon = styled(Icon)`
  vertical-align: middle;
  margin-right: 10px;
`;
const AuthCodeInput = styled.input`
  align-self: center;
  text-align: center;
  font-size: 1.5em;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  margin-bottom: 20px;
`;

interface MFAFormProps {
  step: number;
  loading: boolean;
}

export function MFAForm(props: MFAFormProps) {
  const { t } = useTranslation();
  const [otp, setOtp] = useState("");
  const [mfaAction, setMfaAction] = useState("");
  const dispatch = useDispatch();
  const { userName, mfaEnabled } = useSelector((state: any) => state.users);
  const token = useSelector((state: any) => state.token.key);

  // TODO: The name "verify" is a bit misleading, since it doesn't handle the actual verification,
  // but rather just sends the verification email. Rename?
  const verifyMFAAction = (action: string) => {
    setMfaAction(action);

    dispatch({
      type: TYPES.VERIFY_MFA_ACTION,
      payload: { user: userName, action, token },
    });
  };

  const handleMFAAction = () => {
    dispatch({
      type: mfaAction,
      payload: { data: { otp }, user: userName, token },
    });
  };

  const content = (_step: number) => {
    if (_step === 0) {
      return !props.loading ? (
        <>
          {mfaEnabled ? (
            <>
              <Info>
                <StatusIcon src={icon_status_ok} height={24} />
                {t("mfaForm.isEnabled")}
              </Info>
              <FlexCenter>
                <MFAButton
                  onClick={() => verifyMFAAction(TYPES.DISABLE_MFA_FOR_USER)}
                >
                  {t("mfaForm.buttons.disable")}
                </MFAButton>
              </FlexCenter>
            </>
          ) : (
            <>
              <Info>
                <StatusIcon src={icon_alert} height={24} />
                {t("mfaForm.isDisabled")}
              </Info>
              <FlexCenter>
                <MFAButton
                  onClick={() => verifyMFAAction(TYPES.ENABLE_MFA_FOR_USER)}
                >
                  {t("mfaForm.buttons.enable")}
                </MFAButton>
              </FlexCenter>
            </>
          )}
        </>
      ) : (
        <Info>{t("mfaForm.sendingCode")}</Info>
      );
    } else if (_step === 1) {
      return !props.loading ? (
        <>
          <Info>
            {t("mfaForm.codeSent", {
              email: userName,
            })}
            <br />
            <p>{t("mfaForm.authCodeLabel")}</p>
          </Info>

          <FlexCenter>
            <AuthCodeInput
              onChange={(e) => setOtp(e.target.value)}
              type="text"
              size={6}
            />
          </FlexCenter>
          <FlexCenter>
            <MFAButton onClick={handleMFAAction}>
              {t("mfaForm.buttons.proceed")}
            </MFAButton>
          </FlexCenter>
        </>
      ) : (
        <Info>{t("mfaForm.loading")}</Info>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Title>{t("mfaForm.title")}</Title>
      <Content>{content(props.step)}</Content>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    step: state.mfa.step,
    loading: state.mfa.loading,
  };
};

const MFAFormContainer = connect(mapStateToProps)(MFAForm);

export default MFAFormContainer;
