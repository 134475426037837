import { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import { LoginForm } from "../containers/LoginPage";
import { Devices } from "./Devices";
import { Analytics } from "./Analytics";
import { Containers } from "./Containers";
import { Register } from "./Register/Register";

import "../css/colors.css";
import Admin from "./Admin/Admin";
import Scale from "./Scale/Scale";
import { ResetPassword } from "./ResetPassword/ResetPassword";
import { ForgotPassword } from "./ResetPassword/ForgotPassword";
import { MobilePrivacyPolicy } from "./PrivacyPolicy/MobilePrivacyPolicy";

import { toast } from "react-toastify";
import { Spinner } from "../components/Spinner";
import Toast from "../components/Common/Toast";
import { ServiceToolLogin } from "./ServiceToolLogin";
import { ErrorComponent } from "../components/ErrorComponent";

const App = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Toast
        hideProgressBar={true}
        autoClose={5000}
        position={toast.POSITION.TOP_CENTER}
      />
      <ErrorBoundary
        fallbackRender={(props) => (
          // ErrorBoundaries will catch any regular rendering errors, handle them here
          <ErrorComponent message={props?.error?.message as string} />
        )}
      >
        <Switch>
          <Route exact path="/" component={LoginForm} />
          <Route path="/servicetool/login" component={ServiceToolLogin} />
          <Route exact path="/devices" component={Devices} />
          <Route exact path="/devices/:deviceId" component={Devices} />
          <Route exact path="/analytics" component={Analytics} />
          <Route exact path="/analytics/:deviceId" component={Analytics} />
          <Route exact path="/containers" component={Containers} />
          <Route exact path="/register" component={Register} />
          <Route path="/admin" component={Admin} />
          <Route path="/scale" component={Scale} />
          <Route exact path="/resetpassword" component={ResetPassword} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route
            exact
            path="/privacypolicy/mobile"
            component={MobilePrivacyPolicy}
          />
        </Switch>
      </ErrorBoundary>
    </Suspense>
  );
};

export default App;
