import { call, put } from "redux-saga/effects";
import * as api from "../api/anomalies";
import * as TYPES from "../constants/actionTypes";
import { Notify } from "../components/Common/Notify";
import { getNotificationByAction } from "../utils/utils";

/**
 * Fetch anomaly data.
 * @export
 * @param {*} action
 */
export function* fetchAnomalies(action: any) {
  try {
    const result = yield call(api.getAnomalies, action);
    yield put({
      type: TYPES.FETCH_ANOMALIES_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    Notify.error(getNotificationByAction(TYPES.FETCH_ANOMALIES_FAILURE));
    yield put({ type: TYPES.FETCH_ANOMALIES_FAILURE });
  }
}

// Update Anomaly data
export function* updateAnomalies(action: any) {
  try {
    yield call(api.updateAnomaly, action);
    yield put({
      type: TYPES.UPDATE_ANOMALIES_SUCCESS,
      payload: action.payload.data,
    });
    Notify.success(getNotificationByAction(TYPES.UPDATE_ANOMALIES_SUCCESS));
  } catch (err) {
    yield put({
      type: TYPES.UPDATE_ANOMALIES_FAILURE,
    });
    Notify.error(getNotificationByAction(TYPES.UPDATE_ANOMALIES_FAILURE));
  }
}

// Anomaly types
export function* fetchAnomalyTypes(action: any) {
  try {
    const result = yield call(api.getAnomalyTypes, action);
    yield put({
      type: TYPES.FETCH_ANOMALY_TYPES_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    yield put({
      type: TYPES.FETCH_ANOMALY_TYPES_FAILURE,
    });
    Notify.error(getNotificationByAction(TYPES.FETCH_ANOMALY_TYPES_FAILURE));
  }
}

export function* createAnomalyType(action: any) {
  try {
    const result = yield call(api.upsertAnomalyType, action);
    yield put({
      type: TYPES.CREATE_ANOMALY_TYPE_SUCCESS,
      payload: result.data,
    });
    Notify.success(getNotificationByAction(TYPES.CREATE_ANOMALY_TYPE_SUCCESS));
  } catch (err) {
    yield put({
      type: TYPES.CREATE_ANOMALY_TYPE_FAILURE,
    });
    Notify.error(getNotificationByAction(TYPES.CREATE_ANOMALY_TYPE_FAILURE));
  }
}

export function* updateAnomalyType(action: any) {
  try {
    yield call(api.upsertAnomalyType, action);
    yield put({
      type: TYPES.UPDATE_ANOMALY_TYPE_SUCCESS,
      payload: action.payload.data,
    });
    Notify.success(getNotificationByAction(TYPES.UPDATE_ANOMALY_TYPE_SUCCESS));
  } catch (err) {
    yield put({
      type: TYPES.UPDATE_ANOMALY_TYPE_FAILURE,
    });
    Notify.error(getNotificationByAction(TYPES.UPDATE_ANOMALY_TYPE_FAILURE));
  }
}

export function* deleteAnomalyType(action: any) {
  try {
    yield call(api.deleteAnomalyType, action);
    yield put({
      type: TYPES.DELETE_ANOMALY_TYPE_SUCCESS,
      payload: action.payload.data,
    });
    Notify.success(getNotificationByAction(TYPES.DELETE_ANOMALY_TYPE_SUCCESS));
  } catch (err) {
    yield put({
      type: TYPES.DELETE_ANOMALY_TYPE_FAILURE,
    });
    Notify.error(getNotificationByAction(TYPES.DELETE_ANOMALY_TYPE_FAILURE));
  }
}
