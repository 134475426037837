import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Modal } from "./Common/Modal";
import { FormInput } from "./Common/FormInput";
import { FlexBoxWrapper } from "./Common";
import { FormSubmitButton } from "./Common/FormSubmitButton";
import { Formik } from "formik";
import { useSelector } from "react-redux";

import MFAForm from "./../components/MFAForm";
import { RequestUserDeletion } from "./RequestUserDeletion";

const ProfileModalContainer = styled.div`
  margin: 40px 12.5% 40px;
`;
const Divider = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  margin: 20px 0;
`;

interface ProfileModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleChangeLanguage: (language: string) => void;
}

export function ProfileModal(props: ProfileModalProps) {
  const { t } = useTranslation();
  const language = useSelector((state: any) => state.users.language);
  const languageOptions = [
    {
      value: "en",
      text: t("language.english", "English"),
    },
    {
      value: "ru",
      text: t("language.russian", "Russian"),
    },
    {
      value: "fi",
      text: t("language.finnish", "Finnish"),
    },
    {
      value: "pl",
      text: t("language.polish", "Polish"),
    },
  ];

  const initialValues = {
    language: language ? language : languageOptions[0].value,
  };

  const onSubmit = (values: any) => {
    props.handleChangeLanguage(values.language);
  };

  const onRender = (formikProps: any) => {
    const { errors, values, touched, handleChange, handleBlur } = formikProps;

    return (
      <ProfileModalContainer>
        <MFAForm />
        <Divider />
        <>
          <FormInput
            // TODO: Move this form into its own component?
            id="language"
            type="select"
            label={t("header.userMenu.profileModal.language", "Language")}
            component="select"
            options={languageOptions}
            error={touched.language && errors.language}
            value={values.language}
            onChange={handleChange}
            onBlur={handleBlur}
            labelColor={"#000"}
            noHorizontalMargin={true}
          />
          <FlexBoxWrapper marginTop={20}>
            <FormSubmitButton onClick={() => onSubmit(values)}>
              {t("header.userMenu.profileModal.submitButton", "Submit")}
            </FormSubmitButton>
          </FlexBoxWrapper>
        </>
        <Divider />
        <RequestUserDeletion />
      </ProfileModalContainer>
    );
  };

  const content: JSX.Element = (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={onRender}
    />
  );

  return (
    <Modal
      isOpen={props.isOpen}
      handleClose={props.handleClose}
      content={content}
      title={t("header.userMenu.profileModal.header", "Profile")}
      height={"660px"}
    />
  );
}
