import React, { useState, useEffect } from "react";

// React hook to include an external script
export const useScript = (
  url: string,
  namespace: string,
  options?: { [key: string]: string | boolean }
) => {
  const [script, setScript] = useState({});

  useEffect(() => {
    if ((window as any)[namespace]) {
      console.info(`Script '${namespace}' already loaded, skipping.`);
      return;
    }

    const _script = document.createElement("script");

    _script.src = url;
    _script.onload = () =>
      setScript({ [namespace]: (window as any)[namespace] });
    Object.assign(script, options);

    document.body.appendChild(_script);

    return () => {
      document.body.removeChild(_script);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (script) {
    return script;
  }

  return null;
};

// React hook to detect when a click happens outside of the ref'd component, e.g. to close a dropdown menu when clicking outside of it
export const useClickOutsideDetector = (
  ref: React.RefObject<HTMLElement>,
  callback: Function
) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // TODO: find out correct type for this
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
};
